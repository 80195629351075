import axios from "axios";
import config from "../config"; // Import URL từ file config

const API_URL = config.apiBaseUrl + "/advertisements";
const API_BASE_URL = config.apiBaseUrl;

// Lấy tất cả quảng cáo
const getAllAdvertisements = () => {
  return axios.get(API_URL);
};

// Lấy quảng cáo theo ID
const getAdvertisementById = (id) => {
  return axios.get(API_URL + id);
};

// Tạo quảng cáo mới
const createAdvertisement = (imageLink, screenConfigId) => {
  return axios.post(API_URL, {
    image_link: imageLink,
    screen_config_id: screenConfigId,
  });
};

// Cập nhật quảng cáo
const updateAdvertisement = (id, imageLink, screenConfigId) => {
  return axios.put(`${API_URL}/${id}`, {
    image_link: imageLink,
    screen_config_id: screenConfigId,
  });
};

// Cập nhật quảng cáo media
const updateConfiguration = (id, schedule, defaultUrls) => {
  return axios.put(`${config.apiBaseUrl + "/media-schedule"}`, {
    id: id,
    name: "My Media Schedule",
    defaultUrls: defaultUrls,
    schedule: schedule,
  });
};

// Xóa quảng cáo
const deleteAdvertisement = (id) => {
  return axios.delete(`${API_URL}/${id}`);
};

// tao channel
const createChannel = (channelName, advertisements) => {
  return axios.post(API_URL + "/channel", {
    name_channel: channelName,
    advertisements,
  });
};

const deleteChannel = (id) => {
  return axios.delete(API_URL + "/channel/" + id);
};

///////// media ///////////
// 🔹 Lấy danh sách Channels
const fetchScreenConfigs = () => {
  return axios.get(`${API_BASE_URL}/screen-configs`);
};

// // Lấy quảng cáo media
// const fetchMediaSchedule = () => {
//   return axios.get(config.apiBaseUrl + "/media-schedule");
// };

// 🔹 Lấy MediaSchedule theo Channel ID
const fetchMediaSchedule = (channelId) => {
  return axios.get(`${API_BASE_URL}/media-schedule?channel_id=${channelId}`);
};

// 🔹 Tạo Channel mới
const createScreenConfig = (data) => {
  return axios.post(`${API_BASE_URL}/screen-configs`, data);
};

// 🔹 Tạo MediaSchedule mặc định cho Channel
const createMediaSchedule = (channelId, data) => {
  return axios.post(
    `${API_BASE_URL}/media-schedule?channel_id=${channelId}`,
    data
  );
};

// Export các API dưới dạng một object để sử dụng
const AdvertisementApi = {
  getAllAdvertisements,
  getAdvertisementById,
  createAdvertisement,
  updateAdvertisement,
  deleteAdvertisement,
  createChannel,
  deleteChannel,
  updateConfiguration,

  fetchScreenConfigs,
  fetchMediaSchedule,
  createScreenConfig,
  createMediaSchedule,
};

export default AdvertisementApi;
