import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import AdvertisementApi from "../../services/advertisementService";
import MediaScheduleManager from "./AdvertisementMedia"; // Import MediaSchedule
import ImagePicker from "../BaseComponent/ImagePicker";
import config from "../../config";

const BaseUrl = "https://api.avik.tech/api";
const apiBaseUrl = config.apiBaseUrl;

const ScreenConfigManager = () => {
  const [channels, setChannels] = useState([]); // Danh sách Channels (ScreenConfig)
  const [selectedChannel, setSelectedChannel] = useState(null); // Channel đang chọn
  const [openDialog, setOpenDialog] = useState(false);
  const [newChannel, setNewChannel] = useState({ name_channel: "" }); // Channel mới
  const [mediaSchedule, setMediaSchedule] = useState(null); // Dữ liệu MediaSchedule
  const [imageURL, setImageURL] = useState("");
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState(null);
  const [imageVersion, setImageVersion] = useState(0); // Trigger re-render khi upload

  // ✅ Fetch danh sách Channels khi load UI
  useEffect(() => {
    fetchChannels();
    fetchImages();
  }, []);

  // 🔹 Lấy danh sách Channels từ Backend
  const fetchChannels = async () => {
    try {
      const response = await AdvertisementApi.fetchScreenConfigs();

      const filteredData = response.data.filter(
        (item) => item.media_schedule_id !== 0
      );
      console.log(filteredData);

      setChannels(filteredData);
      if (response.data.length > 0) {
        setSelectedChannel(filteredData[0].media_schedule_id);
      }
    } catch (error) {
      console.error("Failed to fetch channels:", error);
      toast.error("Failed to load channels ❌");
    }
  };

  // 🔹 Khi chọn Channel, fetch MediaSchedule tương ứng
  useEffect(() => {
    if (selectedChannel) {
      console.log(selectedChannel);
      fetchMediaSchedule(selectedChannel);
    }
  }, [selectedChannel]);

  // ✅ Fetch MediaSchedule theo Channel
  const fetchMediaSchedule = async (channelId) => {
    try {
      const response = await AdvertisementApi.fetchMediaSchedule(channelId);
      console.log("fetch media", response.data);
      setMediaSchedule(response.data);
    } catch (error) {
      console.error("Error fetching media schedule:", error);
    }
  };

  // ✅ Tạo Channel mới
  const createNewChannel = async () => {
    if (!newChannel.name_channel.trim()) {
      toast.error("Channel name cannot be empty!");
      return;
    }

    try {
      const response = await AdvertisementApi.createScreenConfig(newChannel);
      const newChannelData = response.data;
      setChannels([...channels, newChannelData]); // Cập nhật danh sách
      setSelectedChannel(newChannelData.media_schedule_id); // Chọn channel mới
      toast.success("Channel created successfully! ✅");
      setOpenDialog(false);
      setNewChannel({ name_channel: "" });

      // 🔹 Tạo MediaSchedule mặc định cho Channel mới
      createDefaultMediaSchedule(newChannelData.media_schedule_id);
    } catch (error) {
      console.error("Failed to create channel:", error);
      toast.error("Failed to create channel ❌");
    }
  };

  // ✅ Tạo MediaSchedule mặc định khi tạo Channel mới
  const createDefaultMediaSchedule = async (channelId) => {
    try {
      const defaultSchedule = {
        name: `Media Schedule - ${channelId}`,
        defaultUrls: [],
        schedule: {},
      };
      const response = await AdvertisementApi.createMediaSchedule(
        channelId,
        defaultSchedule
      );
      setMediaSchedule(response.data);
      toast.success("Default media schedule created! ✅");
    } catch (error) {
      console.error("Failed to create default media schedule:", error);
      toast.error("Failed to create media schedule ❌");
    }
  };

  // image
  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      setFiles(Array.from(selectedFiles)); // Chuyển FileList thành mảng
    }
    // setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      const response = await fetch(apiBaseUrl + "/upload-invalid-version", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Upload result:", result);

      // Reset lại file sau khi upload thành công
      setFiles([]);
      setImageVersion((prev) => prev + 1); // Cập nhật version để buộc ảnh re-render

      // Gọi lại fetchImages nếu cần cập nhật UI
      fetchImages();
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      handleUpload(files);
    }
  }, [files]);

  const fetchImages = () => {
    fetch(apiBaseUrl + "/invalid-version")
      .then((response) => response.json())
      .then((data) => {
        setImages(data);
        console.log("check link", data, `${apiBaseUrl}/${data}`);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* ✅ Chọn Channel */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Select Channel</InputLabel>
          <Select
            label="Select Channel"
            value={selectedChannel || ""}
            onChange={(e) => {
              console.log("e.target.value", e.target.value);
              setSelectedChannel(e.target.value);
            }}
          >
            {channels.map((channel) => (
              <MenuItem
                key={channel.media_schedule_id}
                value={channel.media_schedule_id}
              >
                {channel.name_channel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* ✅ Nút Tạo Channel */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ ml: 2 }}
          onClick={() => setOpenDialog(true)}
        >
          Create Channel
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Xếp theo chiều dọc (Button trên, Image dưới)
          alignItems: "center", // Căn giữa theo chiều ngang
          gap: 2, // Khoảng cách giữa Button và Image
          padding: 2,
        }}
      >
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }} // Ẩn input file
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: "#1e88e5",
              color: "#fff",
              padding: "5px 20px",
              textTransform: "none",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
              minWidth: 200,
              ml: 2,
            }}
          >
            Upload Images InvalidVersion
          </Button>
        </label>
        {images && (
          <img
            src={apiBaseUrl + images}
            alt="Selected"
            style={{ width: 150, cursor: "pointer", borderRadius: "10px" }}
            sx={{ ml: 2 }}
            onError={(e) => {
              e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
              e.target.src =
                "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
            }}
          />
        )}
      </Box>

      {/* ✅ Hiển thị MediaScheduleManager khi có dữ liệu */}
      {mediaSchedule && <MediaScheduleManager mediaSchedule={mediaSchedule} />}

      {/* ✅ Dialog Tạo Channel */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Channel</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Channel Name"
            value={newChannel.name_channel}
            onChange={(e) =>
              setNewChannel({ ...newChannel, name_channel: e.target.value })
            }
            sx={{ marginTop: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={createNewChannel}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScreenConfigManager;
