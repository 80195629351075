import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  Typography,
  Tooltip,
} from "@mui/material";
import { Add, Save } from "@mui/icons-material";
import { TableCell, tableCellClasses } from "@mui/material";
import { COLORS } from "../../theme/themeColor";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import productService from "../../services/ProductService"; // Sử dụng dịch vụ sản phẩm
// const BaseUrl = config.apiBaseUrl;
const BaseUrl = "https://api.avik.tech/api";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const initialProducts = [
  {
    id: 1,
    name: "Espresso",
    schedule: {
      Monday: ["08:00-12:00", "15:00-18:00"],
      Wednesday: ["10:00-14:00"],
      Saturday: ["09:00-17:00"],
    },
  },
  {
    id: 2,
    name: "Latte",
    schedule: null, // Nếu null -> Hiển thị cả ngày
  },
  {
    id: 3,
    name: "Cafe",
    schedule: {
      Monday: ["08:00-12:00", "15:00-18:00"],
      Wednesday: ["10:00-14:00"],
      Saturday: null,
      Thursday: [], // Coi như ALL DAY
    },
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.BLUE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // height: 20,
}));

const defaultFullDayTime = ["00:00-23:59"];

const ProductScheduleBoard = ({ productsData, handleUpdateSchedule }) => {
  const [products, setProducts] = useState(productsData);
  const [updated, setUpdated] = useState(false); // Kiểm tra khi nào cần update

  useEffect(() => {
    setProducts(productsData);
  }, [productsData]);

  // ✅ Hợp nhất khung giờ để tránh trùng lặp
  const mergeTimeSlots = (timeSlots) => {
    if (!timeSlots || timeSlots.length === 0) return [];

    const toMinutes = (time) => {
      const [hour, min] = time.split(":").map(Number);
      return hour * 60 + min;
    };

    const sortedSlots = timeSlots
      .map((slot) => {
        const [start, end] = slot.split("-");
        return { start: toMinutes(start), end: toMinutes(end) };
      })
      .sort((a, b) => a.start - b.start);

    const merged = [];
    let prev = sortedSlots[0];

    for (let i = 1; i < sortedSlots.length; i++) {
      const current = sortedSlots[i];
      if (current.start <= prev.end) {
        prev.end = Math.max(prev.end, current.end);
      } else {
        merged.push(prev);
        prev = current;
      }
    }
    merged.push(prev);

    return merged.map(
      (slot) =>
        `${String(Math.floor(slot.start / 60)).padStart(2, "0")}:${String(
          slot.start % 60
        ).padStart(2, "0")}-${String(Math.floor(slot.end / 60)).padStart(
          2,
          "0"
        )}:${String(slot.end % 60).padStart(2, "0")}`
    );
  };

  // ✅ Thêm thời gian hiển thị
  const handleAddTime = (productId, day) => {
    const timeSlot = prompt("Enter time slot (e.g. 08:00-12:00)");
    if (!timeSlot) return;

    // ✅ Kiểm tra định dạng hợp lệ
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(timeSlot)) {
      alert("Invalid format! Use HH:mm-HH:mm (e.g., 08:00-12:00)");
      return;
    }

    const [start, end] = timeSlot.split("-").map((t) => {
      const [hour, min] = t.split(":").map(Number);
      return hour * 60 + min; // Convert HH:mm thành phút
    });

    // ✅ Kiểm tra giờ bắt đầu phải nhỏ hơn giờ kết thúc
    if (start >= end) {
      alert("Invalid time range! Start time must be before end time.");
      return;
    }

    setProducts((prevProducts) =>
      prevProducts.map((p) => {
        if (p.ID !== productId) return p;

        const newSchedule = p.schedule ? { ...p.schedule } : {};
        if (!newSchedule[day]) newSchedule[day] = [];

        newSchedule[day] = mergeTimeSlots([...newSchedule[day], timeSlot]);

        setUpdated(true);
        return { ...p, schedule: newSchedule };
      })
    );
  };

  // ✅ Xóa thời gian hiển thị đúng cách
  const handleDeleteTime = (productId, day, timeSlot) => {
    setProducts((prevProducts) =>
      prevProducts.map((p) => {
        if (p.ID !== productId) return p;

        if (!p.schedule || !p.schedule[day]) return p;

        const newSchedule = { ...p.schedule };
        newSchedule[day] = newSchedule[day].filter((t) => t !== timeSlot);

        if (newSchedule[day].length === 0) delete newSchedule[day];

        setUpdated(true);
        return { ...p, schedule: newSchedule };
      })
    );
  };

  // ✅ Gửi cập nhật lên server
  //   const handleUpdateSchedule = async () => {
  //     console.log(products[0]);
  //     try {
  //       const response = await productService.updateProductSchedule(products);
  //     } catch (error) {
  //       console.error("Error updating schedule:", error);
  //     }
  //   };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Product Display Schedule
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <strong>ID</strong>
              </StyledTableCell>
              <StyledTableCell>
                <strong>Product</strong>
              </StyledTableCell>

              {weekDays.map((day) => (
                <StyledTableCell key={day} align="center">
                  <strong>{day}</strong>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.ID}>
                <TableCell>
                  <strong>{product.ID}</strong>
                </TableCell>
                <TableCell align="center">
                  <img
                    src={BaseUrl + product.image_link_square}
                    alt={product.image_link_square}
                    style={{ width: 100, borderRadius: "10px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image";
                    }}
                  />
                  <div>
                    <strong>{product.name}</strong>
                  </div>
                </TableCell>
                {weekDays.map((day) => {
                  const scheduleForDay =
                    product.schedule === null ||
                    !product.schedule[day] ||
                    product.schedule[day].length === 0
                      ? defaultFullDayTime
                      : product.schedule[day];

                  return (
                    <TableCell key={day} align="center">
                      {scheduleForDay.map((timeSlot) => (
                        <div key={timeSlot}>
                          <Tooltip
                            key={timeSlot}
                            title={`Show Time: ${timeSlot}`}
                          >
                            <Chip
                              label={
                                timeSlot === "00:00-23:59"
                                  ? "ALL DAY"
                                  : timeSlot
                              }
                              color={
                                timeSlot === "00:00-23:59"
                                  ? "success"
                                  : "primary"
                              }
                              sx={{
                                m: 0.5,
                                color: "white",
                              }}
                              onDelete={() =>
                                handleDeleteTime(product.ID, day, timeSlot)
                              }
                            />
                          </Tooltip>
                        </div>
                      ))}
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<Add />}
                          onClick={() => handleAddTime(product.ID, day)}
                          sx={{ mt: 1 }}
                        >
                          Add
                        </Button>
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {updated && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          sx={{ mt: 2 }}
          onClick={() => handleUpdateSchedule(products)}
        >
          Update Schedule
        </Button>
      )}
    </Box>
  );
};

export default ProductScheduleBoard;
