import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import AdminSetup from "./components/Auth/AdminSetup";
import ForgotPass from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import AddCampaign from "./components/BackOffice/CampaignForm";
import CampaignList from "./components/BackOffice/CampaignList";
import Categories from "./components/BackOffice/CategoriesList";
import Combo from "./components/BackOffice/ComboList";
import GroupModifier from "./components/BackOffice/GroupModifierList";
import Menu from "./components/BackOffice/MenuList";
import Modifier from "./components/BackOffice/ModifiersList";
import AddProduct from "./components/BackOffice/ProductForm";

import Advertisements from "./components/BackOffice/AdvertisementManager";
import AdsMedia from "./components/BackOffice/MediaScheduleManager";
import ProductsList from "./components/BackOffice/ProductsList";
import AddRecipe from "./components/BackOffice/Recipelist";
import Size from "./components/BackOffice/SizesList";
import StoreStatus from "./components/BackOffice/StoreStatus";
import Temperture from "./components/BackOffice/TemperaturesList";
import Upload from "./components/BackOffice/UploadList";

import AdjustmentRecord from "./components/BackOffice/AdjustmentRecordList";
import ExportRecordForm from "./components/BackOffice/ExportRecordForm";
import ExportRecordList from "./components/BackOffice/ExportRecordList";
import ImportRecordForm from "./components/BackOffice/ImportRecordForm";
import ImportRecordList from "./components/BackOffice/ImportRecordList";
import InventoryList from "./components/BackOffice/InventoryList";
import MaterialForm from "./components/BackOffice/MaterialForm";
import MaterialList from "./components/BackOffice/MaterialList";
import UnitConversion from "./components/BackOffice/UnitConversion";
import UnitList from "./components/BackOffice/UnitList";
import WareHouseList from "./components/BackOffice/WarehouseList";
import ManufacturingForm from "./components/BackOffice/ManufacturingForm";

import Topup from "./components/BackOffice/TopupList";
import UserManagement from "./components/BackOffice/UserManagement";
import Dashboard from "./components/Dashboard/Dashboard";
import Layout from "./components/Dashboard/Layout";

import CustomerManager from "./components/BackOffice/CustomerManager";
/////
import ReportList from "./components/BackOffice/ReportList";
import ReportOrder from "./components/BackOffice/ReportOrder";
import ReportOrderTimeRange from "./components/BackOffice/ReportOrderTimeRange";
import ReportProduct from "./components/BackOffice/ReportProducts";
import ReportType from "./components/BackOffice/ReportType";
import ReportWallet from "./components/BackOffice/ReportWallet";

import AppConfig from "./components/BackOffice/AppConfig";
import ProtectedRoute from "./utils/ProtectedRoute";
// import Unauthorized from "./components/Unauthorized";
// Import ToastContainer và CSS từ react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import theme from "./theme/theme";
function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <BrowserRouter> */}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/setup" element={<AdminSetup />} />
          <Route path="/forgotPass" element={<ForgotPass />} />
          {/* <Route path="/unauthorized" element={<Unauthorized />} /> */}
          <Route element={<Layout />}>
            <Route element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/user-management" element={<UserManagement />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/customerManager" element={<CustomerManager />} />
            </Route>
            {/* app config */}
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/app-config" element={<AppConfig />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/products" element={<ProductsList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/Categories" element={<Categories />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/Modifiers" element={<Modifier />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/Sizes" element={<Size />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/Temperatures" element={<Temperture />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/add-product/:id?" element={<AddProduct />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/uploads" element={<Upload />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/groupModifier" element={<GroupModifier />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/combo" element={<Combo />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/menu" element={<Menu />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/topup" element={<Topup />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/advertisements" element={<Advertisements />} />
            </Route>

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/ads_media" element={<AdsMedia />} />
            </Route>

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/campaign" element={<CampaignList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/add-campaign/:id?" element={<AddCampaign />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/storeStatus" element={<StoreStatus />} />
            </Route>

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/material" element={<MaterialList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/materialForm/:id?" element={<MaterialForm />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/importRecord" element={<ImportRecordList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route
                path="/importRecordForm/:id?"
                element={<ImportRecordForm />}
              />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/exportRecord" element={<ExportRecordList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route
                path="/exportRecordForm/:id?"
                element={<ExportRecordForm />}
              />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/adjustmentRecord" element={<AdjustmentRecord />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/inventory" element={<InventoryList />} />
            </Route>

            {/* <Route element={<PrivateRoute roles={["admin"]} />}>
              <Route path="/materialForm/:id?" element={<MaterialForm/>} />
            </Route> */}

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route
                path="/manufacturingForm"
                element={<ManufacturingForm />}
              />
            </Route>

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/unitConversion" element={<UnitConversion />} />
            </Route>

            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/unit" element={<UnitList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/warehouse" element={<WareHouseList />} />
            </Route>
            <Route element={<PrivateRoute roles={["admin", "manager"]} />}>
              <Route path="/addRecipe" element={<AddRecipe />} />
            </Route>

            {/* Report */}
            <Route
              element={
                <PrivateRoute
                  roles={["admin", "manager", "report", "reportProducts"]}
                />
              }
            >
              <Route path="/reports" element={<ReportList />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  roles={["admin", "manager", "report", "reportProducts"]}
                />
              }
            >
              <Route path="/reportsOrder" element={<ReportOrder />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  roles={["admin", "manager", "report", "reportProducts"]}
                />
              }
            >
              <Route path="/reportsWallet" element={<ReportWallet />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  roles={["admin", "manager", "report", "reportProducts"]}
                />
              }
            >
              <Route
                path="/reportsOrderTimeRange"
                element={<ReportOrderTimeRange />}
              />
            </Route>
            <Route
              element={<PrivateRoute roles={["admin", "manager", "report"]} />}
            >
              <Route path="/reportProducts" element={<ReportProduct />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  roles={["admin", "manager", "report", "reportProducts"]}
                />
              }
            >
              <Route path="/reportType" element={<ReportType />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  roles={[
                    "staff",
                    "manager",
                    "admin",
                    "report",
                    "reportRevenue",
                    "reportProducts",
                    "reportsOrderTimeRange",
                  ]}
                />
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      {/* Thêm ToastContainer vào cuối cây component */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* </BrowserRouter> */}
    </ThemeProvider>
  );
}

export default App;
