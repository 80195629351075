import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import config from "../../config";
import AdvertisementApi from "../../services/advertisementService"; // Import AdvertisementApi
import ImagePicker from "../BaseComponent/ImagePicker";
import { styled } from "@mui/material/styles";
const BaseUrl = config.apiBaseUrl;
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const AdvertisementManager = () => {
  const [screenConfigs, setScreenConfigs] = useState([]); // Thay đổi thành screenConfigs để phù hợp với API
  const [open, setOpen] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelNameError, setChannelNameError] = useState(false); // Track validation error
  const [advertisements, setAdvertisements] = useState([
    { image_link: "", time_slot: "" },
  ]);

  // Open/Close Dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setChannelName("");
    setAdvertisements([{ image_link: "", time_slot: "" }]);
  };

  // Handle Input Changes
  const handleChannelNameChange = (e) => setChannelName(e.target.value);

  const handleAdvertisementChange = (index, field, value) => {
    const updatedAds = [...advertisements];
    updatedAds[index][field] = value;
    setAdvertisements(updatedAds);
  };

  const handleAddAdvertisement = () => {
    setAdvertisements([...advertisements, { image_link: "", time_slot: "" }]);
  };

  const handleRemoveAdvertisement = (index) => {
    const updatedAds = advertisements.filter((_, i) => i !== index);
    setAdvertisements(updatedAds);
  };

  const validateForm = () => {
    if (!channelName.trim()) {
      setChannelNameError(true);
      return false;
    }
    return true;
  };
  // Submit Form
  const handleSubmit = async () => {
    if (!validateForm()) return; // Validate before submitting
    try {
      const response = await AdvertisementApi.createChannel(
        channelName,
        advertisements
      );
      console.log("Channel Created:", response.data);
      toast.success("Channel created successfully!");
      handleClose();
      loadAdvertisements();
    } catch (error) {
      console.error("Error creating channel:", error);
      toast.error("Failed to create channel. Please try again.");
    }
  };

  const handleDeleteChannel = async (id) => {
    if (!window.confirm("Are you sure you want to delete this channel?"))
      return;

    try {
      await AdvertisementApi.deleteChannel(id);
      toast.success("Channel deleted successfully!");
      loadAdvertisements();
    } catch (error) {
      console.log("Failed to delete channel:", error);
      toast.error("Failed to delete channel. Please try again.");
    }
  };
  ////////////

  useEffect(() => {
    loadAdvertisements();
  }, []);

  // Load tất cả quảng cáo
  const loadAdvertisements = async () => {
    try {
      const { data } = await AdvertisementApi.getAllAdvertisements(); // Sử dụng AdvertisementApi
      const response = data.filter((item) => item.media_schedule_id == 0);
      console.log(data);
      // Sắp xếp danh sách trước khi render
      const sortedScreenConfigs = [...response].sort((a, b) =>
        a.name_channel.localeCompare(b.name_channel)
      );
      setScreenConfigs(sortedScreenConfigs); // Cập nhật dữ liệu cho screenConfigs
    } catch (error) {
      console.error("Error fetching advertisements:", error);
    }
  };

  // del advertisement
  const handleDel = async (id) => {
    console.log(id);
    try {
      await AdvertisementApi.deleteAdvertisement(id); // Cập nhật API với hình ảnh mới
      loadAdvertisements(); // Reload dữ liệu sau khi cập nhật
      toast.success("Created successfully.");
    } catch (error) {
      console.log("Failed to save:", error);
      toast.error("Failed to Created.");
    }
  };

  // Hàm để xử lý việc chọn ảnh mới
  const handleImageSelect = async (ad, url) => {
    console.log("Selected URL:", url, ad.id);

    try {
      await AdvertisementApi.updateAdvertisement(
        ad.id,
        url,
        ad.screen_config_id
      ); // Cập nhật API với hình ảnh mới
      loadAdvertisements(); // Reload dữ liệu sau khi cập nhật
      toast.success("Updated successfully.");
    } catch (error) {
      console.error("Error updating advertisement:", error);
    }
  };

  const handleSave = async (id) => {
    try {
      await AdvertisementApi.createAdvertisement("", id); // Cập nhật API với hình ảnh mới
      loadAdvertisements(); // Reload dữ liệu sau khi cập nhật
      toast.success("Created successfully.");
    } catch (error) {
      console.log("Failed to save:", error);
      toast.error("Failed to Created.");
    }
  };

  return (
    <div>
      {/* Button to Open Popup */}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Channel
      </Button>
      {/* Hiển thị danh sách quảng cáo theo từng màn hình */}
      {screenConfigs.map((screenConfig) => (
        <Box item key={screenConfig.id} sx={{ padding: 2 }}>
          <Item sx={{ padding: 5 }}>
            <Grid
              container
              spacing={2}
              sx={{ padding: 2 }}
              justifyContent="flex-start"
            >
              {/* Tên ScreenConfig */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h4">
                  Screen {screenConfig.id}: {screenConfig.name_channel}
                </Typography>
              </Grid>

              {/* Nút Delete */}
              <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteChannel(screenConfig.id)}
                >
                  Delete Channel
                </Button>
              </Grid>
            </Grid>

            {screenConfig.name_channel != "Combo" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave(screenConfig.id)}
                sx={{ marginBottom: "16px" }} // Thay đổi giá trị này để điều chỉnh padding
              >
                Add Image Row
              </Button>
            )}

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Image Link</TableCell>
                    {screenConfig.name_channel === "Combo" && (
                      <TableCell>TimeSlot</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {screenConfig.advertisements.map((ad) => (
                    <TableRow key={ad.id}>
                      <TableCell>
                        <ImagePicker
                          onImageSelect={(url) => handleImageSelect(ad, url)}
                          defaultImage={BaseUrl + ad.image_link}
                        />
                      </TableCell>
                      <TableCell>{ad.image_link}</TableCell>
                      {screenConfig.name_channel === "Combo" && (
                        <TableCell>{ad.time_slot}</TableCell>
                      )}

                      {screenConfig.name_channel !== "Combo" && (
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDel(ad.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Box>
      ))}

      {/* Dialog for Creating Channel */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Create New Channel</DialogTitle>
        <DialogContent>
          {/* Channel Name */}
          <Box mb={2}>
            <Typography>Channel Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={channelName}
              onChange={handleChannelNameChange}
              error={channelNameError} // Show error if invalid
              helperText={
                channelNameError ? "Channel name cannot be empty" : ""
              } // Error message
            />
          </Box>

          {/* Advertisements */}
          <Box>
            <Typography>Advertisements</Typography>
            {advertisements.map((ad, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
              >
                <TextField
                  label="Image Link"
                  variant="outlined"
                  value={ad.image_link}
                  onChange={(e) =>
                    handleAdvertisementChange(
                      index,
                      "image_link",
                      e.target.value
                    )
                  }
                  fullWidth
                />
                <TextField
                  label="Time Slot"
                  variant="outlined"
                  value={ad.time_slot}
                  onChange={(e) =>
                    handleAdvertisementChange(
                      index,
                      "time_slot",
                      e.target.value
                    )
                  }
                  fullWidth
                />
                <IconButton
                  color="secondary"
                  onClick={() => handleRemoveAdvertisement(index)}
                >
                  ✖
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddAdvertisement}
              style={{ marginTop: "10px" }}
            >
              Add Advertisement
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdvertisementManager;
