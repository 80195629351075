// 📌 Tính năng đầy đủ
// ✅ Mỗi ngày có nhiều khung giờ, mỗi khung giờ có tập URL riêng.
// ✅ Thêm/Xóa khung giờ và danh sách URL.
// ✅ Popup chỉnh sửa danh sách URL của từng khung giờ.
// ✅ Giữ nguyên logic chọn type, URL, Duration của từng URL trong khung giờ.
// ✅ Có nút Set Default Media để chỉnh sửa tập URL mặc định.
// ✅ Dữ liệu mẫu (initialMediaSchedule) có nhiều ngày, nhiều khung giờ, mỗi khung giờ có tập URL riêng.
// ✅ Nếu ngày nào không có lịch → Hiển thị "ALL DAY" và dùng tập URL mặc định.
// ✅ Thêm Button để thiết lập tập URL mặc định.
// ✅ Cho phép chỉnh sửa/xóa tập URL mặc định.
// ✅ Thêm nút "Update Schedule" để lưu thay đổi.
// ✅ Dùng react-toastify để hiển thị thông báo khi cập nhật thành công.

import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  Stack,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdvertisementApi from "../../services/advertisementService";
import ImagePicker from "../BaseComponent/ImagePicker";
import config from "../../config";

// const BaseUrl = config.apiBaseUrl;
const BaseUrl = "https://api.avik.tech/api";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// 🔹 Danh sách URL có sẵn để chọn
const listUrls = [
  { url: "localhost/image1.png" },
  { url: "localhost/image2.png" },
  { url: "localhost/image3.png" },
  { url: "localhost/image4.png" },
  { url: "localhost/image5.png" },
];

// 🔹 Lịch trình mẫu
const initialMediaSchedule = {
  Sunday: [
    {
      Time: "08:00-12:00",
      urls: [
        {
          index: 1,
          media_type: "image",
          mediaItem: "localhost/image1.png",
          Duration: "30",
        },
      ],
    },
    {
      Time: "15:00-18:00",
      urls: [
        {
          index: 1,
          media_type: "image",
          mediaItem: "localhost/image1.png",
          Duration: "30",
        },
        {
          index: 2,
          media_type: "video",
          mediaItem: "localhost/default2.mp4",
          Duration: "60",
        },
      ],
    },
  ],
  Tuesday: [
    {
      Time: "08:00-12:00",
      urls: [
        {
          index: 1,
          media_type: "image",
          mediaItem: "localhost/image1.png",
          Duration: "30",
        },
      ],
    },
  ],
};

// 🔹 URL mặc định
const defaultMedia = [
  {
    index: 1,
    media_type: "image",
    mediaItem: "localhost/default1.png",
    Duration: "30",
  },
  {
    index: 2,
    media_type: "video",
    mediaItem: "localhost/default2.mp4",
    Duration: "60",
  },
];

const MediaScheduleManager = ({ mediaSchedule }) => {
  const [schedule, setSchedule] = useState({});
  const [defaultUrls, setDefaultUrls] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [newTime, setNewTime] = useState("");
  const [mediaDialog, setMediaDialog] = useState(false);
  const [mediaList, setMediaList] = useState([]);
  const [newMedia, setNewMedia] = useState({
    media_type: "image",
    mediaItem: listUrls[0].url,
    Duration: "30",
  });
  const [defaultDialog, setDefaultDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldImage, setShouldImage] = useState(false);

  // image
  const handleImageSelect = (url) => {
    if (!url) return;

    console.log("Selected URL:", url);

    // ✅ Lấy phần mở rộng của file (jpg, png, mp4, etc.)
    const extension = url.split(".").pop().toLowerCase();
    console.log("check file", extension);

    // ✅ Danh sách phần mở rộng của image & video
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const videoExtensions = ["mp4", "mov", "avi", "mkv", "webm"];

    let mediaType = "image"; // Mặc định là ảnh
    if (videoExtensions.includes(extension)) {
      mediaType = "video";
    } else if (!imageExtensions.includes(extension)) {
      mediaType = "unknown"; // Nếu không thuộc image/video
    }
    console.log("check type", extension, mediaType);

    // ✅ Cập nhật state cho media mới
    const updatedMedia = {
      media_type: mediaType,
      mediaItem: url,
      Duration: "30",
    };

    // ✅ Thêm vào danh sách `mediaList`
    setMediaList((prevList) => [
      ...prevList,
      { index: prevList.length + 1, ...updatedMedia },
    ]);

    // ✅ Cập nhật `newMedia`
    setNewMedia(updatedMedia);
  };

  // 🔹 Thêm khung giờ cho ngày
  const addTimeSlot = (selectedDay) => {
    const timeSlot = prompt("Enter time slot (e.g., 08:00-12:00)");
    if (!timeSlot) return;

    if (!selectedDay || !weekDays.includes(selectedDay)) {
      alert("Invalid day selected!", selectedDay);
      //   return;
    }

    // ✅ Kiểm tra định dạng hợp lệ
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(timeSlot)) {
      alert("Invalid format! Use HH:mm-HH:mm (e.g., 08:00-12:00)");
      return;
    }

    const [start, end] = timeSlot.split("-").map((t) => {
      const [hour, min] = t.split(":").map(Number);
      return hour * 60 + min; // Convert HH:mm thành phút
    });

    // ✅ Kiểm tra giờ bắt đầu phải nhỏ hơn giờ kết thúc
    if (start >= end) {
      alert("Invalid time range! Start time must be before end time.");
      return;
    }

    // ✅ Cập nhật state với thời gian mới
    setSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };
      if (!updatedSchedule[selectedDay]) updatedSchedule[selectedDay] = [];

      // ✅ Thêm khung giờ mới
      const newTimeSlots = [
        ...updatedSchedule[selectedDay],
        { Time: timeSlot, urls: [] },
      ];

      // ✅ Gộp các khung giờ tránh trùng lặp
      const mergedTimeSlots = mergeTimeSlots(newTimeSlots);

      updatedSchedule[selectedDay] = mergedTimeSlots;
      console.log("updata", updatedSchedule);

      return updatedSchedule;
    });

    setOpenDialog(false);
    setNewTime("");
    // setShouldUpdate(true);
  };

  const mergeTimeSlots = (timeSlots) => {
    if (!timeSlots || timeSlots.length === 0) return [];

    const toMinutes = (time) => {
      if (!time || !time.includes(":")) return null;
      const [hour, min] = time.split(":").map(Number);
      return hour * 60 + min;
    };

    // ✅ Lọc bỏ giá trị `undefined`, `null` và kiểm tra tính hợp lệ
    const validSlots = timeSlots
      .filter((slot) => slot && slot.Time && slot.Time.includes("-"))
      .map((slot) => {
        const [start, end] = slot.Time.split("-");
        return {
          start: toMinutes(start),
          end: toMinutes(end),
          urls: slot.urls || [],
        };
      })
      .filter((slot) => slot.start !== null && slot.end !== null)
      .sort((a, b) => a.start - b.start);

    if (validSlots.length === 0) return [];

    // ✅ Hợp nhất các khung giờ trùng lặp
    const merged = [];
    let prev = validSlots[0];

    for (let i = 1; i < validSlots.length; i++) {
      const current = validSlots[i];

      if (current.start <= prev.end) {
        prev.end = Math.max(prev.end, current.end);
        prev.urls = [...prev.urls, ...current.urls];
      } else {
        merged.push(prev);
        prev = current;
      }
    }
    merged.push(prev);

    // ✅ Chuyển lại về định dạng `HH:mm-HH:mm`
    return merged.map((slot) => ({
      Time:
        `${String(Math.floor(slot.start / 60)).padStart(2, "0")}:${String(
          slot.start % 60
        ).padStart(2, "0")}-` +
        `${String(Math.floor(slot.end / 60)).padStart(2, "0")}:${String(
          slot.end % 60
        ).padStart(2, "0")}`,
      urls: slot.urls,
    }));
  };

  // 🔹 Xóa khung giờ khỏi ngày
  const deleteTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    if (updatedSchedule[day].length === 0) delete updatedSchedule[day];
    setSchedule(updatedSchedule);

    setShouldUpdate(true);
  };

  // 🔹 Chỉnh sửa media trong danh sách
  const editMediaItem = (index) => {
    setNewMedia(mediaList[index]);
    setEditIndex(index);
  };

  // 🔹 Mở popup chỉnh sửa tập URL cho khung giờ
  const openMediaDialog = (day, timeSlot) => {
    setSelectedDay(day);
    setSelectedTime(timeSlot);
    const targetSlot = schedule[day]?.find((slot) => slot.Time === timeSlot);
    setMediaList(targetSlot ? targetSlot.urls : []);
    setMediaDialog(true);
  };

  // 🔹 Thêm media URL vào danh sách
  const addMediaItem = () => {
    if (!newMedia.mediaItem) return;
    setMediaList([...mediaList, { index: mediaList.length + 1, ...newMedia }]);

    const url = newMedia.mediaItem.trim();

    // ✅ Lấy phần mở rộng của file (jpg, png, mp4, etc.)
    const extension = url.split(".").pop().toLowerCase();

    console.log("check file", extension);

    // ✅ Danh sách phần mở rộng của image & video
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const videoExtensions = ["mp4", "mov", "avi", "mkv", "webm"];

    let mediaType = "image"; // Mặc định là ảnh
    if (videoExtensions.includes(extension)) {
      mediaType = "video";
    } else if (!imageExtensions.includes(extension)) {
      mediaType = "unknown"; // Nếu không thuộc image/video
    }
    console.log("check type", extension, mediaType);

    setNewMedia({
      media_type: mediaType,
      mediaItem: listUrls[0].url,
      Duration: "30",
    });

    setShouldImage(false);
  };

  // 🔹 Xóa media khỏi danh sách
  const deleteMediaItem = (index) => {
    setMediaList(mediaList.filter((_, i) => i !== index));
  };

  // 🔹 Lưu danh sách media vào lịch trình
  const saveMediaList = () => {
    const updatedSchedule = { ...schedule };
    const targetSlot = updatedSchedule[selectedDay]?.find(
      (slot) => slot.Time === selectedTime
    );
    if (targetSlot) targetSlot.urls = mediaList;
    setSchedule(updatedSchedule);
    setMediaDialog(false);
    setShouldUpdate(true);
  };

  // 🔹 Mở popup chỉnh sửa tập URL mặc định
  const openDefaultDialog = () => {
    setDefaultDialog(true);
    setMediaList(defaultUrls);
  };

  // 🔹 Xóa media khỏi danh sách mặc định
  const deleteDefaultMedia = (index) => {
    setDefaultUrls(defaultUrls.filter((_, i) => i !== index));
    setShouldUpdate(true);
  };
  // 🔹 Lưu danh sách media vào tập URL mặc định
  const saveDefaultMedia = () => {
    setDefaultUrls([...mediaList]);
    setDefaultDialog(false);
    setShouldUpdate(true);
  };

  // 🔹 Cập nhật toàn bộ cấu hình (Gọi API hoặc Lưu vào state)
  const updateConfiguration = async () => {
    // 🚀 Ở đây có thể gọi API để lưu vào database nếu cần
    console.log("Updated Configuration:", {
      id: mediaSchedule.ID,
      schedule,
      defaultUrls,
    });
    try {
      await AdvertisementApi.updateConfiguration(
        mediaSchedule.ID,
        schedule,
        defaultUrls
      ); // Cập nhật API với hình ảnh mới
      toast.success("Configuration updated successfully! ✅");
      fetchMediaSchedule();
    } catch (error) {
      toast.error("Failed to update configuration ❌");
    }
  };

  // ✅ Hàm cập nhật cấu hình (có thể tối ưu debounce nếu cần)
  // const updateConfiguration = useCallback(async () => {
  //   console.log("Updating Configuration...");
  //   // 🚀 Ở đây có thể gọi API để lưu vào database nếu cần
  //   console.log("Updated Configuration:", { schedule, defaultUrls });
  //   try {
  //     await AdvertisementApi.updateConfiguration(schedule, defaultUrls); // Cập nhật API với hình ảnh mới
  //     toast.success("Configuration updated successfully! ✅");
  //     fetchMediaSchedule();
  //   } catch (error) {
  //     toast.error("Failed to update configuration ❌");
  //   }
  // }, [schedule, defaultUrls]);

  // ✅ Theo dõi `schedule` và `defaultUrls`
  useEffect(() => {
    if (shouldUpdate) {
      updateConfiguration();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  // ✅ Hàm fetch dữ liệu từ backend
  const fetchMediaSchedule = async () => {
    try {
      const response = await AdvertisementApi.fetchMediaSchedule(
        mediaSchedule.ID
      );
      const data = response.data; // Lấy phần tử đầu tiên trong danh sách (vì API trả về danh sách)
      console.log(data);
      // ✅ Giải mã JSON trước khi set state
      setSchedule(data.schedule);
      setDefaultUrls(data.defaultUrls);
      //   setSchedule(JSON.parse(data.schedule));
      //   setDefaultUrls(JSON.parse(data.defaultUrls));

      //   toast.success("Fetched media schedule successfully! ✅");
    } catch (error) {
      console.error("Error fetching media schedule:", error);
      //   toast.error("Failed to fetch media schedule ❌");
    }
  };

  // ✅ Gọi API ngay khi component được mount
  useEffect(() => {
    fetchMediaSchedule();
  }, [mediaSchedule]);

  return (
    <Box sx={{ padding: 2 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          borderRadius: 3,
          backgroundColor: "#f9f9f9",
          textAlign: "center",
          mb: 2,
        }}
      >
        {/* ✅ Danh sách Default Media */}
        <Box sx={{ marginTop: 2, padding: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#1976d2",
              textTransform: "uppercase",
              marginBottom: 1,
            }}
          >
            Default Media List
          </Typography>

          {defaultUrls.length > 0 ? (
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              justifyContent="center"
            >
              {defaultUrls.map((media, index) => (
                <Chip
                  key={index}
                  label={`${media.media_type.toUpperCase()} - ${
                    media.mediaItem
                  } (${media.Duration}s)`}
                  sx={{
                    padding: "8px 12px",
                    fontSize: "0.85rem",
                    fontWeight: "500",
                    backgroundColor: "#e3f2fd",
                  }}
                  onDelete={() => deleteDefaultMedia(index)}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="body2" sx={{ color: "#757575" }}>
              No default media available.
            </Typography>
          )}

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="small"
            sx={{
              marginTop: 2,
              fontWeight: "bold",
              width: "100%",
              borderRadius: 2,
            }}
            onClick={openDefaultDialog}
          >
            ADD
          </Button>
        </Box>
      </Paper>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {weekDays.map((day) => (
          <Grid key={day} item xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {/* ✅ Hiển thị tiêu đề ngày */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#1976d2",
                  textTransform: "uppercase",
                  marginBottom: 1,
                }}
              >
                {day}
              </Typography>

              {/* ✅ Danh sách khung giờ */}
              {schedule[day]?.length > 0 ? (
                schedule[day].map((slot, index) => (
                  <Paper
                    key={index}
                    elevation={2}
                    sx={{
                      width: "100%",
                      padding: 2,
                      borderRadius: 2,
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
                      marginBottom: 1.5,
                    }}
                  >
                    {/* ✅ Hiển thị giờ + nút edit, delete */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", color: "#1976d2" }}
                      >
                        {slot.Time}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          color="warning"
                          onClick={() => openMediaDialog(day, slot.Time)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => deleteTimeSlot(day, index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Stack>

                    {/* ✅ Danh sách media */}
                    <List dense sx={{ marginTop: 1 }}>
                      {slot.urls.map((media, idx) => (
                        <ListItem key={idx} disablePadding>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#424242",
                                }}
                              >
                                {media.mediaItem}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  color: "#757575",
                                }}
                              >
                                {media.media_type.toUpperCase()} -{" "}
                                {media.Duration}s
                              </Typography>
                            }
                            sx={{
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                ))
              ) : (
                <Paper
                  elevation={1}
                  sx={{
                    width: "100%",
                    padding: 1.5,
                    borderRadius: 2,
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    ALL DAY
                  </Typography>
                </Paper>
              )}

              {/* ✅ Nút thêm khung giờ */}
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="small"
                sx={{
                  marginTop: 2,
                  fontWeight: "bold",
                  width: "100%",
                  borderRadius: 2,
                }}
                onClick={() => {
                  setSelectedDay(day);
                  console.log("day:", day);
                  addTimeSlot(day);
                }}
              >
                ADD
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* ✅ Popup chỉnh sửa tập URL mặc định */}
      <Dialog open={defaultDialog} onClose={() => setDefaultDialog(false)}>
        <DialogTitle>Manage Default Media</DialogTitle>
        <DialogContent>
          {/* <div> */}
          <ImagePicker
            onImageSelect={handleImageSelect}
            defaultImage={BaseUrl + imageURL}
          />
          {/* </div> */}
          <TextField
            fullWidth
            label="Duration (seconds)"
            type="number"
            value={newMedia.Duration}
            onChange={(e) =>
              setNewMedia({ ...newMedia, Duration: e.target.value })
            }
            sx={{ marginTop: 1 }}
          />

          <Box sx={{ marginTop: 2 }}>
            {mediaList.map((media, index) => (
              <Chip
                key={index}
                label={`${media.media_type.toUpperCase()} - ${
                  media.mediaItem
                } (${media.Duration}s)`}
                color="secondary"
                onClick={() => editMediaItem(index)}
                onDelete={() => deleteMediaItem(index)}
                deleteIcon={<CloseIcon />}
                sx={{ marginRight: 1, marginTop: 1 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDefaultDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={saveDefaultMedia}>
            Save Default Media
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={mediaDialog} onClose={() => setMediaDialog(false)}>
        <DialogTitle>Edit Media List</DialogTitle>
        <DialogContent>
          <ImagePicker
            onImageSelect={handleImageSelect}
            defaultImage={BaseUrl + imageURL}
          />

          <TextField
            fullWidth
            label="Duration (seconds)"
            type="number"
            value={newMedia.Duration}
            onChange={(e) =>
              setNewMedia({ ...newMedia, Duration: e.target.value })
            }
            sx={{ marginTop: 1 }}
          />

          <Box sx={{ marginTop: 2 }}>
            {mediaList.map((media, index) => (
              <Chip
                key={index}
                label={`${media.media_type.toUpperCase()} - ${
                  media.mediaItem
                } (${media.Duration}s)`}
                color="secondary"
                onClick={() => editMediaItem(index)}
                onDelete={() => deleteMediaItem(index)}
                deleteIcon={<CloseIcon />}
                sx={{ marginRight: 1, marginTop: 1 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMediaDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={saveMediaList}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaScheduleManager;
