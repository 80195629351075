import axios from "axios";
import config from "../config"; // Import URL từ file config

const API_URL = config.apiBaseUrl + "/customers";
const API_AMBASS = config.apiBaseUrl + "/ambassador-ref";
// Lấy tất cả quảng cáo
const getAllCustomers = () => {
  return axios.get(API_URL);
};

const updateCustomer = (customerID, value) => {
  return axios.put(API_URL + "/" + customerID, value);
};

const searchCustomers = (searchQuery) => {
  return axios.get(API_URL + "/search", {
    params: {
      search: searchQuery,
    },
  });
};

// Tạo mới combo
const createAmbass = (mobile_number, code, customer_id, ref_type) => {
  return axios.post(API_AMBASS, {
    mobile_number,
    code,
    customer_id,
    ref_type,
  });
};

// Export các API dưới dạng một object để sử dụng
const AdvertisementApi = {
  getAllCustomers,
  updateCustomer,
  searchCustomers,
  createAmbass,
};

export default AdvertisementApi;
