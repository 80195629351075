const permissions = {
  admin: [
    "/user-management",
    "/customerManager",
    "/reports",
    "/reportType",
    "/reportsOrder",
    "/reportsWallet",
    "/reportsOrderTimeRange",
    "/reportProducts",
    "/storeStatus",
    "/app-config",
    "/campaign",
    "/products",
    "/Sizes",
    "/Modifiers",
    "/groupModifier",
    "/Temperatures",
    "/Categories",
    "/uploads",
    "/combo",
    "/menu",
    "/topup",
    "/advertisements",
    "/ads_media",
    "/inventory",
    "/manufacturingForm",
    "/material",
    "/importRecord",
    "/exportRecord",
    "/adjustmentRecord",
    "/unitConversion",
    "/unit",
    "/warehouse",
    "/managerRole",
  ],
  manager: [
    "/reports",
    "/reportType",
    "/reportsOrder",
    "/reportsOrderTimeRange",
    "/reportProducts",
    "/storeStatus",
    "/app-config",
    "/campaign",
    "/products",
    "/Sizes",
    "/Modifiers",
    "/groupModifier",
    "/Temperatures",
    "/Categories",
    "/uploads",
    "/manufacturingForm",
    "/material",
    "/importRecord",
    "/exportRecord",
    "/adjustmentRecord",
    "/unitConversion",
    "/unit",
    "/warehouse",
  ],
  cashier: ["/reportsOrder", "/topup", "/advertisements"],
  report: [
    "/reports",
    "/reportType",
    "/reportsOrder",
    "/reportsOrderTimeRange",
    "/reportProducts",
  ],
  reportRevenue: ["/reports"],
  reportProducts: ["/reportsOrder", "/reportsOrderTimeRange"],
  // reportsOrderTimeRange: ["/reportsOrderTimeRange"],
};

export default permissions;
