import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { fetchReportType } from "../../services/ReportService";

const OrdersUI = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);
  const [orderRows, setorderRows] = useState([]);
  const [summaryRows, setsummaryRows] = useState([]);
  const [rowSpanData, setrowSpanData] = useState({});
  const [paymentTypeRowSpanData, setPaymentTypeRowSpanData] = useState({});

  useEffect(() => {
    handleFetchReport();
  }, [startDate, endDate]);

  const handleFetchReport = async () => {
    setLoading(true);
    setError(null);

    try {
      const res = await fetchReportType(startDate, endDate);
      console.log(res.data);
      if (res?.data) {
        setReportData(res.data);
      } else {
        setReportData({ details: [], summary: {} });
      }
    } catch (err) {
      console.error(err.message);
      setError(err.message);
      setReportData({ details: [], summary: {} });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (reportData) {
      const orderRows = processOrderDetails(reportData.details || []);
      const summaryRows = processSummaryData(reportData.summary || {});
      const { rowSpanData, paymentTypeRowSpanData } =
        calculateRowSpan(summaryRows);
      setorderRows(orderRows);
      setsummaryRows(summaryRows);
      setrowSpanData(rowSpanData);
      setPaymentTypeRowSpanData(paymentTypeRowSpanData);
    }
  }, [reportData]);

  const processOrderDetails = (details) => {
    if (!details || !Array.isArray(details)) return [];
    return details.map((order) => {
      const transactions = (order.transactions || []).map((transaction) => {
        const utxos = (transaction.utxos || [])
          .map((utxo) => `${utxo.Type}: $${utxo.Amount.toFixed(2)}`)
          .join(", ");
        return `ID: ${transaction.id}, Wallet: ${transaction.wallet_id}, Amount: $${transaction.amount}, UTXOs: [${utxos}]`;
      });

      return {
        createdAt: new Date(order.CreatedAt).toLocaleString(),
        orderId: order.order_id,
        userName: order.user_name,
        status: order.status,
        orderType: order.order_type,
        paymentType: order.payment_type,
        tip: order.tip,
        gst: order.gst,
        pst: order.pst,
        discount: order.discount,
        giftcode: order.giftcode,
        eta: order.eta,
        total: order.total_products,
        PriceProduct: order.total_price,
        details: (order.order_details || [])
          .map((detail) => `${detail.name} - $${detail.price}`)
          .join(", "),
        transactions: transactions.join(" | "),
      };
    });
  };

  const processSummaryDataBK = (summary) => {
    const rows = [];
    Object.entries(summary).forEach(([orderType, paymentData]) => {
      Object.entries(paymentData).forEach(([paymentType, subData]) => {
        if (typeof subData === "object") {
          Object.entries(subData).forEach(([subType, amount]) => {
            rows.push({ orderType, paymentType, subType, amount });
          });
        } else {
          rows.push({
            orderType,
            paymentType,
            subType: "Total",
            amount: subData,
          });
        }
      });
    });
    return rows;
  };

  const processSummaryData = (summary) => {
    const rows = [];
    Object.entries(summary).forEach(([orderType, paymentData]) => {
      Object.entries(paymentData).forEach(([paymentType, subData]) => {
        if (typeof subData === "object" && subData !== null) {
          Object.entries(subData).forEach(([subType, amount]) => {
            if (subType !== "orders") {
              // Thêm các mục con (subType)
              rows.push({ orderType, paymentType, subType, amount });
            }
          });

          // Thêm mục tổng (total)
          // if (subData.total !== undefined) {
          //   rows.push({
          //     orderType,
          //     paymentType,
          // subType: "Total",
          //     amount: subData.total,
          //   });
          // }
        }
      });
    });
    return rows;
  };

  const calculateRowSpan = (data) => {
    const rowSpanData = {};
    const paymentTypeRowSpanData = {};

    data.forEach((row) => {
      rowSpanData[row.orderType] = (rowSpanData[row.orderType] || 0) + 1;

      const key = `${row.orderType}-${row.paymentType}`;
      paymentTypeRowSpanData[key] = (paymentTypeRowSpanData[key] || 0) + 1;
    });

    return { rowSpanData, paymentTypeRowSpanData };
  };

  let orderTypeRendered = {}; // Theo dõi `Order Type` đã render hay chưa
  let paymentTypeRendered = {}; // Theo dõi `Order Type` đã render hay chưa

  return (
    <div>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />

      {loading && (
        <Box mt={2}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {!loading && !error && (
        <>
          {/* Summary Table */}
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            Summary
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Sub Type</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryRows.map((row, index) => {
                  if (!row.paymentType) {
                    // Nếu paymentType là chuỗi rỗng, bỏ qua hàng này
                    return null;
                  }

                  return (
                    <TableRow key={index}>
                      {/* Cột Order Type với rowSpan */}
                      {!orderTypeRendered[row.orderType] ? (
                        <TableCell rowSpan={rowSpanData[row.orderType]}>
                          {row.orderType}
                        </TableCell>
                      ) : null}

                      {/* Cột Payment Type với rowSpan */}
                      {!paymentTypeRendered[
                        `${row.orderType}-${row.paymentType}`
                      ] ? (
                        <TableCell
                          rowSpan={
                            paymentTypeRowSpanData[
                              `${row.orderType}-${row.paymentType}`
                            ]
                          }
                        >
                          {row.paymentType}
                        </TableCell>
                      ) : null}

                      <TableCell>{row.subType}</TableCell>
                      <TableCell>${row.amount.toFixed(2)}</TableCell>
                      {/* .toFixed(2) */}
                      {/* Đánh dấu Payment Type đã render */}
                      {
                        (paymentTypeRendered[
                          `${row.orderType}-${row.paymentType}`
                        ] = true)
                      }
                      {/* Đánh dấu Order Type đã render */}
                      {(orderTypeRendered[row.orderType] = true)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Orders Table */}
          <Typography variant="h6" gutterBottom>
            Orders Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Idx</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>OrderID</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Payment Type</TableCell>

                  <TableCell>GST</TableCell>
                  <TableCell>PST</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Voucher</TableCell>
                  <TableCell>Price Products</TableCell>
                  <TableCell>Tip</TableCell>
                  <TableCell>Total Products</TableCell>
                  <TableCell>Order Details</TableCell>
                  <TableCell>Transactions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell>{row.orderId}</TableCell>
                    <TableCell>{row.userName}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.orderType}</TableCell>
                    <TableCell>{row.paymentType}</TableCell>

                    <TableCell>{row.gst}</TableCell>
                    <TableCell>{row.pst}</TableCell>
                    <TableCell>{row.discount}</TableCell>
                    <TableCell>{row.giftcode}</TableCell>
                    <TableCell>{row.PriceProduct}</TableCell>
                    <TableCell>{row.tip}</TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell>{row.details}</TableCell>
                    <TableCell>{row.transactions}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default OrdersUI;
