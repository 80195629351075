import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { Link, Navigate, Outlet } from "react-router-dom";

const normalizeName = (name) =>
  name
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\s]/gi, "")
    .replace(/\s+/g, " ");

const PieChartsByCategory = ({ data }) => {
  console.log("datats", data);
  const savouryItems = [
    "chicken caesar salad",
    "frittata croissant",
    "ham cheese croissant",
    "ham potato salad",
    "mushroom avocado ciabatta",
    "mushroom onion sourdough",
    "turkey onion sourdough",
    "viet sub",
    "turkey rotis sourdough",
    "caprese bite",
    "vegan pate bun",
    "breakfast sandwich",
  ];

  const updatedCategories = data
    .map((category) => {
      if (category.category_name === "Bakery") {
        const savouryProducts = category.products
          .filter((product) =>
            savouryItems.includes(normalizeName(product.product_name))
          )
          .sort((a, b) => b.total_quantity - a.total_quantity);

        const pastryProducts = category.products
          .filter(
            (product) =>
              !savouryItems.includes(normalizeName(product.product_name))
          )
          .sort((a, b) => b.total_quantity - a.total_quantity);

        return [
          {
            category_name: "Savoury",
            products: savouryProducts,
          },
          {
            category_name: "Pastry",
            products: pastryProducts,
          },
        ];
      } else {
        return [
          {
            category_name: category.category_name,
            products: category.products.sort(
              (a, b) => b.total_quantity - a.total_quantity
            ),
          },
        ];
      }
    })
    .flat();

  const categoryOrder = ["Pastry", "Savoury", "Coffee", "Tea"];
  const sortedCategories = [
    ...categoryOrder
      .map((order) =>
        updatedCategories.find((category) => category.category_name === order)
      )
      .filter(Boolean),
    ...updatedCategories.filter(
      (category) => !categoryOrder.includes(category.category_name)
    ),
  ];

  const [expandedTables, setExpandedTables] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: "total_quantity",
    direction: "descending",
  });

  const toggleViewMore = (categoryName) => {
    setExpandedTables((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  const sortProducts = (products, key) => {
    return [...products].sort((a, b) => {
      if (sortConfig.direction === "ascending") {
        return a[key] - b[key];
      } else if (sortConfig.direction === "descending") {
        return b[key] - a[key];
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key, direction });
  };

  const token = localStorage.getItem("token");
  if (!token) return <Navigate to="/login" />;
  const user = jwtDecode(token);
  console.log(user);

  const renderSummaryTable = () => {
    const summaryData = sortedCategories.map((category) => {
      const totalPrice = category.products.reduce(
        (sum, product) => sum + (product.total_price || 0), // Ensure total_price is defined
        0
      );
      const totalQuantity = category.products.reduce(
        (sum, product) => sum + (product.total_quantity || 0), // Ensure total_quantity is defined
        0
      );
      return {
        category_name: category.category_name,
        total_price: totalPrice,
        total_quantity: totalQuantity,
      };
    });

    // Calculate grand totals for the summary row
    const grandTotalPrice = summaryData.reduce(
      (sum, item) => sum + item.total_price,
      0
    );
    const grandTotalQuantity = summaryData.reduce(
      (sum, item) => sum + item.total_quantity,
      0
    );

    return (
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: "16px",
          width: "50%", // Restrict width to 50% of the screen
          margin: "0 auto", // Center the table
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "0.9rem" }}>
                <strong>Category</strong>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "0.9rem" }}>
                <strong>Total Sales ($)</strong>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "0.9rem" }}>
                <strong>Total Quantity</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryData.map((item) => (
              <TableRow key={item.category_name}>
                <TableCell sx={{ fontSize: "0.8rem" }}>
                  {item.category_name}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: "0.8rem" }}>
                  {item.total_price.toFixed(2)}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: "0.8rem" }}>
                  {item.total_quantity}
                </TableCell>
              </TableRow>
            ))}
            {/* Summary row */}
            <TableRow>
              <TableCell sx={{ fontSize: "0.8rem" }}>
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "0.8rem" }}>
                <strong>{grandTotalPrice.toFixed(2)}</strong>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "0.8rem" }}>
                <strong>{grandTotalQuantity}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTables = (categories) => {
    let filteredCategories = categories;
    // 7788857497
    // if (user.mobile_number === "6047619287"){
    if (
      user.mobile_number === "6047619287" ||
      user.mobile_number === "7788857497"
    ) {
      filteredCategories = categories.filter(
        (category) => category.category_name !== "Gelato"
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        {filteredCategories.map((category, index) => {
          const isExpanded = expandedTables[category.category_name] || false;
          const sortedProducts = sortConfig.key
            ? sortProducts(category.products, sortConfig.key)
            : category.products;

          const visibleProducts = isExpanded
            ? sortedProducts
            : sortedProducts.slice(0, 12);

          const totalPrice = sortedProducts.reduce(
            (sum, product) => sum + product.total_price,
            0
          );
          const totalQuantity = sortedProducts.reduce(
            (sum, product) => sum + product.total_quantity,
            0
          );

          return (
            <Box
              key={index}
              sx={{
                flex: "0 0 calc(25% - 16px)",
                maxWidth: "calc(25% - 16px)",
                minWidth: "250px",
                boxSizing: "border-box",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "100%",
                  overflowX: "auto",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: "0.9rem", padding: "4px 8px" }}
                      >
                        <strong>{category.category_name}</strong>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: "0.9rem",
                          padding: "4px 8px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Button
                          onClick={() => handleSort("total_price")}
                          sx={{
                            fontSize: "0.8rem",
                            minWidth: "auto",
                            padding: 0,
                          }}
                        >
                          <strong>Total ($)</strong>
                        </Button>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: "0.9rem",
                          padding: "4px 8px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Button
                          onClick={() => handleSort("total_quantity")}
                          sx={{
                            fontSize: "0.8rem",
                            minWidth: "auto",
                            padding: 0,
                          }}
                        >
                          <strong>Qty</strong>
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleProducts.map((product) => (
                      <TableRow key={product.product_name}>
                        <TableCell
                          sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                        >
                          {product.product_name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                        >
                          {product.total_price.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                        >
                          {product.total_quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                      >
                        <strong>Summary</strong>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                      >
                        <strong>{totalPrice.toFixed(2)}</strong>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
                      >
                        <strong>{totalQuantity}</strong>
                      </TableCell>
                    </TableRow>
                    {category.products.length > 12 && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Button
                            onClick={() =>
                              toggleViewMore(category.category_name)
                            }
                            sx={{ fontSize: "0.8rem", padding: 0 }}
                          >
                            {isExpanded ? "View Less" : "View More"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      {/* Render Summary Table */}
      {renderSummaryTable()}
      {/* Render Individual Tables */}
      {renderTables(sortedCategories)}
    </Box>
  );
};

//
const SummaryUI = ({ category }) => {
  return (
    <Box sx={{ padding: "16px" }}>
      <PieChartsByCategory data={category} />
    </Box>
  );
};

export default SummaryUI;
