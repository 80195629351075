import {
  People as PeopleIcon,
  Layers as LayersIcon,
  LocalOffer as LocalOfferIcon,
  ShoppingCart as ShoppingCartIcon,
  Straighten as StraightenIcon,
  Tune as TuneIcon,
  Thermostat as ThermostatIcon,
  Category as CategoryIcon,
  CloudUpload as CloudUploadIcon,
  Inventory2 as InventoryIcon,
  SwapHoriz as SwapIcon,
  Store as WarehouseIcon,
  FileUpload as FileUploadIcon,
  FileDownload as FileDownloadIcon,
  RestaurantMenu as RestaurantMenuIcon,
  Divider,
} from "@mui/icons-material";

const routes = [
  { type: "divider" },
  { path: "/user-management", label: "User Management", icon: <PeopleIcon /> },
  {
    path: "/customerManager",
    label: "Customer Management",
    icon: <PeopleIcon />,
  },
  { path: "/managerRole", label: "Role Manager", icon: <PeopleIcon /> },

  { type: "divider" },
  { path: "/reports", label: "Report Revenue", icon: <LayersIcon /> },
  { path: "/reportType", label: "Report Type", icon: <LayersIcon /> },
  { path: "/reportsOrder", label: "Report Order", icon: <LayersIcon /> },
  { path: "/reportsWallet", label: "Report Wallet", icon: <LayersIcon /> },
  {
    path: "/reportsOrderTimeRange",
    label: "Report Order Time Range",
    icon: <LayersIcon />,
  },
  { path: "/reportProducts", label: "Report Product", icon: <LayersIcon /> },

  { type: "divider" },
  { path: "/storeStatus", label: "Store", icon: <LayersIcon /> },
  { path: "/app-config", label: "App Config", icon: <LayersIcon /> },
  { path: "/campaign", label: "Campaign", icon: <LocalOfferIcon /> },
  { path: "/products", label: "Products", icon: <ShoppingCartIcon /> },
  { path: "/combo", label: "Combos", icon: <ShoppingCartIcon /> },
  // { path: "/Sizes", label: "Sizes", icon: <StraightenIcon /> },
  { path: "/Modifiers", label: "Modifiers", icon: <TuneIcon /> },
  { path: "/groupModifier", label: "Group Modifiers", icon: <LayersIcon /> },
  // { path: "/Temperatures", label: "Temperatures", icon: <ThermostatIcon /> },
  { path: "/Categories", label: "Categories", icon: <CategoryIcon /> },
  { path: "/uploads", label: "Uploads", icon: <CloudUploadIcon /> },

  { type: "divider" },

  { path: "/menu", label: "Menu", icon: <RestaurantMenuIcon /> },
  { path: "/topup", label: "Top-up", icon: <ShoppingCartIcon /> },
  {
    path: "/advertisements",
    label: "Advertisements",
    icon: <CloudUploadIcon />,
  },
  {
    path: "/ads_media",
    label: "ADSMedia",
    icon: <CloudUploadIcon />,
  },

  { type: "divider" },
  { path: "/inventory", label: "Inventory", icon: <InventoryIcon /> },
  { path: "/material", label: "Material", icon: <CategoryIcon /> },
  { path: "/importRecord", label: "Import Record", icon: <FileUploadIcon /> },
  { path: "/exportRecord", label: "Export Record", icon: <FileDownloadIcon /> },
  {
    path: "/adjustmentRecord",
    label: "Adjustment Record",
    icon: <FileDownloadIcon />,
  },
  { path: "/unitConversion", label: "Unit Conversion", icon: <SwapIcon /> },
  { path: "/unit", label: "Unit", icon: <StraightenIcon /> },
  { path: "/warehouse", label: "Warehouse", icon: <WarehouseIcon /> },
];

export default routes;
