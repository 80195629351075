// src/components/CustomerTable.js
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const CustomerTable = ({
  customer,
  searchQuery,
  reloadFlag,
  onSelectCustomer,
  addGroup,
  selectedGroupCustomer,
  listCustomerOfGroup,
  onRefferal,
}) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCustomers = async () => {
    setLoading(true);
    setError(null);
    try {
      let url = "http://localhost:3001/customers";
      if (searchQuery) {
        url += `?q=${encodeURIComponent(searchQuery)}`;
      }
      const response = await axios.get(url);
      setCustomers(response.data);
    } catch (err) {
      setError("Failed to fetch customers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    //   fetchCustomers();
    // }, 500); // Debounce search by 500ms

    // return () => clearTimeout(delayDebounceFn);
    console.log("customers");
    setCustomers(customer);
  }, [customer]);

  if (loading) {
    return (
      <Paper sx={{ p: 4, textAlign: "center" }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper sx={{ p: 4, textAlign: "center" }}>
        <Typography color="error">{error}</Typography>
      </Paper>
    );
  }

  if (customers?.length === 0) {
    return (
      <Paper sx={{ p: 4, textAlign: "center" }}>
        <Typography>No customers found.</Typography>
      </Paper>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customer table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>RefID</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>DeleteAt</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers?.map((customer, index) => (
            <TableRow
              key={index}
              hover
              // onClick={() => onSelectCustomer(customer)}
              // sx={{ cursor: "pointer" }}
            >
              <TableCell>{customer.id}</TableCell>
              <TableCell>{customer.username}</TableCell>
              <TableCell>{customer.email}</TableCell>
              <TableCell>{customer.code}</TableCell>
              <TableCell>{customer.mobile_number}</TableCell>
              <TableCell>{customer.ref_id}</TableCell>
              <TableCell>{customer.role}</TableCell>
              <TableCell>{customer.delete}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    onSelectCustomer(customer);
                  }}
                  color="primary"
                  variant="outlined"
                  sx={{ marginRight: 1, marginBottom: 1, width: 150 }}
                >
                  Edit Customer
                </Button>

                <Button
                  onClick={() => {
                    onRefferal(customer);
                  }}
                  color="primary"
                  variant="outlined"
                  sx={{ marginRight: 1, marginBottom: 1, width: 150 }}
                >
                  Add Refferal
                </Button>

                <Button
                  onClick={() => {
                    addGroup(customer);
                  }}
                  color={
                    listCustomerOfGroup.some((item) => item.id === customer.id)
                      ? "success"
                      : "primary"
                  }
                  variant="outlined"
                  sx={{ marginRight: 1, marginBottom: 1, width: 150 }}
                >
                  Add Group ({selectedGroupCustomer?.name})
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerTable;
