import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Alert,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {
  fetchOrderReport,
  fetchOrderDetails,
} from "../../services/ReportService";
import SummaryUI from "./ReportTableProducts";
import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

const normalizeName = (name) =>
  name
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\s]/gi, "")
    .replace(/\s+/g, " ");

const savouryItems = [
  "chicken caesar salad",
  "frittata croissant",
  "ham cheese croissant",
  "ham potato salad",
  "mushroom avocado ciabatta",
  "mushroom onion sourdough",
  "turkey onion sourdough",
  "viet sub",
  "turkey rotis sourdough",
  "caprese bite",
  "vegan pate bun",
  "breakfast sandwich",
];

const OrderReport = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // useEffect(() => {
  //   handleFetchReport();
  // }, []);

  const handleFetchReport = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await fetchOrderReport(startDate, endDate);
      setReportData(data.data);
    } catch (err) {
      console.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchReportOrderDetails = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const res = await fetchOrderDetails(startDate, endDate);
      // setReportData(data.data);
      console.log(res.data);

      return res.data;
    } catch (err) {
      console.error(err.message);
      // setError(err.message);
    }
    return null;
  };

  const calculateOrderTotal = (orderDetails) => {
    return orderDetails.reduce(
      (total, detail) => total + detail.price * detail.quantity,
      0
    );
  };

  const exportExcel = async () => {
    const data = await handleFetchReportOrderDetails();

    if (!data?.all_order_details) return;
    const orders = data.all_order_details;

    // Lọc các đơn hàng có payment_type = "Wallet" và tính tổng total_products
    const totalProducts = orders
      .filter((order) => order.payment_type === "Wallet") // Chỉ lấy đơn hàng có payment_type = 'Wallet'
      .reduce((sum, order) => sum + order.total_products, 0); // Tính tổng total_products

    console.log("Total Products (Wallet):", totalProducts);

    // if (!reportData?.all_order_details) return;
    // const orders = reportData.all_order_details;
    const rows = orders.map((order) => {
      const orderTotal = calculateOrderTotal(order.order_details);
      const productDetails = order.order_details
        .map(
          (detail) =>
            `${detail.name} (Price: ${detail.price}, Qty: ${
              detail.quantity
            }, Modifiers: ${
              detail.selectedModifiers?.map((mod) => mod.name).join(", ") ||
              "None"
            })`
        )
        .join(" | ");

      return [
        order.ID,
        // dayjs(order.CreatedAt).format("DD MMM YYYY, h:mm A"),
        order.CreatedAt,
        order.user_name,
        order.total_products,
        order.tip,
        order.gst,
        order.pst,
        order.percent_discount,
        order.payment_type,
        order.order_type,
        order.staff_name,
        order.gift_code,
        orderTotal.toFixed(2), // Total price calculated
        productDetails,
      ];
    });

    const headers = [
      "Order ID",
      "CreatedAt",
      "User Name",
      "Total Products",
      "Tip",
      "GST",
      "PST",
      "Discount",
      "Payment Type",
      "Order Type",
      "Staff Name",
      "Gift Code",
      "Total Price ($)",
      "Product Details",
    ];

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, `OrderReport_${startDate}_${endDate}.xlsx`);
  };

  const prepareBarChartData = () => {
    // Determine the starting day of the week based on the current date
    const todayIndex = dayjs().day(); // Returns 0 (Sunday) to 6 (Saturday)

    // Generate a list of weekdays starting from today
    const weekdays = Array(7)
      .fill(0)
      .map((_, i) =>
        dayjs()
          .day((todayIndex + i) % 7)
          .format("ddd")
      ); // Rotate weekdays to start from today

    // Initialize arrays for the current week and last week data
    const currentWeekData = Array(7).fill(0);
    const lastWeekData = Array(7).fill(0);

    if (Array.isArray(reportData?.currentWeekData)) {
      reportData.currentWeekData.forEach((entry) => {
        const adjustedIndex = (entry.weekday - todayIndex + 7) % 7; // Adjust the index to align with the starting day
        currentWeekData[adjustedIndex] = entry.total_products;
      });
    }

    if (Array.isArray(reportData?.lastWeekData)) {
      reportData.lastWeekData.forEach((entry) => {
        const adjustedIndex = (entry.weekday - todayIndex + 7) % 7; // Adjust the index for the last week data
        lastWeekData[adjustedIndex] = entry.total_products;
      });
    }

    return { weekdays, currentWeekData, lastWeekData };
  };

  const prepareRealtimeComparisonData = () => {
    if (!reportData?.realtimeProductSales) return [];
    const groupedCategories = reportData.realtimeProductSales.reduce(
      (acc, product) => {
        if (product.category_name === "Bakery") {
          const normalizedProductName = normalizeName(product.product_name);
          const categoryKey = savouryItems.includes(normalizedProductName)
            ? "Savoury"
            : "Pastry";

          if (!acc[categoryKey]) acc[categoryKey] = [];
          acc[categoryKey].push(product);
        } else {
          if (!acc[product.category_name]) acc[product.category_name] = [];
          acc[product.category_name].push(product);
        }
        return acc;
      },
      {}
    );

    const sortedCategories = [
      "Pastry",
      "Savoury",
      "Coffee",
      "Tea",
      "Gelato",
    ].map((category) => ({
      category_name: category,
      products: groupedCategories[category] || [],
    }));

    return sortedCategories.filter((cat) => cat.products.length > 0);
  };

  // const realtimeCategoryCharts = () => {
  //   if (!reportData?.realtimeProductSales || !reportData?.lastWeekProductSales) return null;

  //   // Normalize products by category and product name for current and last week
  //   const groupedCategories = reportData.realtimeProductSales.reduce((acc, product) => {
  //     const normalizedProductName = normalizeName(product.product_name);
  //     const categoryKey =
  //       product.category_name === "Bakery" && savouryItems.includes(normalizedProductName)
  //         ? "Savoury"
  //         : product.category_name === "Bakery"
  //         ? "Pastry"
  //         : product.category_name;

  //     if (!acc[categoryKey]) acc[categoryKey] = {};
  //     if (!acc[categoryKey][normalizedProductName]) {
  //       acc[categoryKey][normalizedProductName] = {
  //         product_name: product.product_name,
  //         currentQuantity: 0,
  //         lastWeekQuantity: 0,
  //       };
  //     }
  //     acc[categoryKey][normalizedProductName].currentQuantity += product.total_quantity;
  //     return acc;
  //   }, {});

  //   reportData.lastWeekProductSales.forEach((product) => {
  //     const normalizedProductName = normalizeName(product.product_name);
  //     const categoryKey =
  //       product.category_name === "Bakery" && savouryItems.includes(normalizedProductName)
  //         ? "Savoury"
  //         : product.category_name === "Bakery"
  //         ? "Pastry"
  //         : product.category_name;

  //     if (!groupedCategories[categoryKey]) groupedCategories[categoryKey] = {};
  //     if (!groupedCategories[categoryKey][normalizedProductName]) {
  //       groupedCategories[categoryKey][normalizedProductName] = {
  //         product_name: product.product_name,
  //         currentQuantity: 0,
  //         lastWeekQuantity: 0,
  //       };
  //     }
  //     groupedCategories[categoryKey][normalizedProductName].lastWeekQuantity += product.total_quantity;
  //   });

  //   // Convert grouped categories into sorted data
  //   const categories = Object.entries(groupedCategories).map(([key, products]) => ({
  //     category_name: key,
  //     products: Object.values(products), // Convert product object to array
  //   }));

  //   // Fixed category order
  //   const categoryOrder = ["Pastry", "Savoury", "Coffee", "Tea", "Gelato"];
  //   const sortedCategories = [
  //     ...categoryOrder
  //       .map((category) =>
  //         categories.find((cat) => cat.category_name === category)
  //       )
  //       .filter(Boolean),
  //     ...categories.filter(
  //       (cat) => !categoryOrder.includes(cat.category_name)
  //     ),
  //   ];

  //   // Generate Highcharts options for each category
  //   return sortedCategories.map((category) => {
  //     const chartOptions = {
  //       chart: { type: "column", backgroundColor: "transparent" },
  //       title: { text: `${category.category_name} - Realtime Sales` },
  //       xAxis: {
  //         categories: category.products.map((product) => product.product_name),
  //       },
  //       yAxis: { min: 0, title: { text: "Quantity" } },
  //       series: [

  //         {
  //           name: "Last WeekDay",
  //           data: category.products.map((product) => product.lastWeekQuantity),
  //           color: "#FF7F50",
  //         },
  //         {
  //           name: "Today",
  //           data: category.products.map((product) => product.currentQuantity),
  //           color: "#228B22",
  //         },
  //       ],
  //     };

  //     return (
  //       <Grid item xs={12} sm={6} md={4} key={category.category_name}>
  //         <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  //       </Grid>
  //     );
  //   });
  // };
  const realtimeCategoryCharts = () => {
    if (!reportData?.realtimeProductSales || !reportData?.lastWeekProductSales)
      return null;

    // Normalize products by category and product name for current and last week
    const groupedCategories = reportData.realtimeProductSales.reduce(
      (acc, product) => {
        const normalizedProductName = normalizeName(product.product_name);
        const categoryKey =
          product.category_name === "Bakery" &&
          savouryItems.includes(normalizedProductName)
            ? "Savoury"
            : product.category_name === "Bakery"
            ? "Pastry"
            : product.category_name;

        if (!acc[categoryKey]) acc[categoryKey] = {};
        if (!acc[categoryKey][normalizedProductName]) {
          acc[categoryKey][normalizedProductName] = {
            product_name: product.product_name,
            currentQuantity: 0,
            lastWeekQuantity: 0,
          };
        }
        acc[categoryKey][normalizedProductName].currentQuantity +=
          product.total_quantity;
        return acc;
      },
      {}
    );

    reportData.lastWeekProductSales.forEach((product) => {
      const normalizedProductName = normalizeName(product.product_name);
      const categoryKey =
        product.category_name === "Bakery" &&
        savouryItems.includes(normalizedProductName)
          ? "Savoury"
          : product.category_name === "Bakery"
          ? "Pastry"
          : product.category_name;

      if (!groupedCategories[categoryKey]) groupedCategories[categoryKey] = {};
      if (!groupedCategories[categoryKey][normalizedProductName]) {
        groupedCategories[categoryKey][normalizedProductName] = {
          product_name: product.product_name,
          currentQuantity: 0,
          lastWeekQuantity: 0,
        };
      }
      groupedCategories[categoryKey][normalizedProductName].lastWeekQuantity +=
        product.total_quantity;
    });

    // Convert grouped categories into sorted data
    const categories = Object.entries(groupedCategories).map(
      ([key, products]) => ({
        category_name: key,
        products: Object.values(products), // Convert product object to array
      })
    );

    // Fixed category order
    const categoryOrder = ["Pastry", "Savoury", "Coffee", "Tea", "Gelato"];
    const sortedCategories = [
      ...categoryOrder
        .map((category) =>
          categories.find((cat) => cat.category_name === category)
        )
        .filter(Boolean),
      ...categories.filter((cat) => !categoryOrder.includes(cat.category_name)),
    ];

    // Calculate totals for the summary table
    const summaryTotals = sortedCategories.reduce(
      (acc, category) => {
        category.products.forEach((product) => {
          acc.totalQuantity += product.currentQuantity;
          acc.lastWeekQuantity += product.lastWeekQuantity;
        });
        return acc;
      },
      { totalQuantity: 0, lastWeekQuantity: 0 }
    );

    // Generate Highcharts options for each category
    const charts = sortedCategories.map((category) => {
      const chartOptions = {
        chart: { type: "column", backgroundColor: "transparent" },
        title: { text: `${category.category_name} - Realtime Sales` },
        xAxis: {
          categories: category.products.map((product) => product.product_name),
        },
        yAxis: { min: 0, title: { text: "Quantity" } },
        series: [
          {
            name: "Last WeekDay",
            data: category.products.map((product) => product.lastWeekQuantity),
            color: "#FF7F50",
          },
          {
            name: "Today",
            data: category.products.map((product) => product.currentQuantity),
            color: "#228B22",
          },
        ],
      };

      return (
        <Grid item xs={12} sm={6} md={4} key={category.category_name}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Grid>
      );
    });

    return (
      <>
        <Grid container spacing={2}>
          {charts}
        </Grid>
        {/* Summary Table */}
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Typography variant="h6" align="center" sx={{ margin: 2 }}>
            Realtime Product Sales Summary
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Total Products Sold (Today)</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Total Products Sold (Last WeekDay)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {summaryTotals.totalQuantity}
                </TableCell>
                <TableCell align="center">
                  {summaryTotals.lastWeekQuantity}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  const { weekdays, currentWeekData, lastWeekData } = prepareBarChartData();

  const barChartOptions = {
    chart: { type: "column", backgroundColor: "transparent" },
    title: {
      text: "Revenue Comparison (Last 7 Days vs Same Period Last Week)",
    },
    xAxis: { categories: weekdays, title: { text: "Weekdays" } },
    yAxis: { min: 0, title: { text: "Revenue ($)" } },
    series: [
      { name: "Last Week", data: lastWeekData, color: "#FF7F50" },
      { name: "Current Week", data: currentWeekData, color: "#228B22" },
    ],
  };

  const paginatedData = reportData?.all_order_details?.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const renderOrderDetails = (order) => (
    <Box>
      {order.order_details.map((detail, idx) => (
        <Box key={idx} sx={{ marginBottom: "8px" }}>
          {`${detail.name} - Qty: ${detail.quantity}, Price: ${
            detail.price
          }, Modifiers: ${
            detail.selectedModifiers?.map((mod) => mod.name).join(", ") ||
            "None"
          }`}
        </Box>
      ))}
    </Box>
  );

  ///////////////////////

  const exportExcelProducts = () => {
    // if (!reportData?.all_order_details) return;
    if (!reportData?.group_category) {
      console.error(
        "Error: reportData is not an array or is undefined",
        reportData
      );
      return;
    }
    // Gộp dữ liệu thành một mảng duy nhất
    const mergedArray = reportData?.group_category.flatMap((category) =>
      category.products.map((product) => ({
        category_name: category.category_name,
        product_name: product.product_name,
        total_price: product.total_price,
        total_quantity: product.total_quantity,
      }))
    );

    if (!mergedArray.length) return;

    const rows = mergedArray.map((item) => [
      item.category_name,
      item.product_name,
      item.total_quantity,
      item.total_price, // Format giá tiền
    ]);

    const headers = [
      "Category Name",
      "Product Name",
      "Total Quantity",
      "Total Price ($)",
    ];

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // Xuất file với ngày tháng
    const formattedStart = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEnd = dayjs(endDate).format("YYYY-MM-DD");
    XLSX.writeFile(
      workbook,
      `ProductReport_${formattedStart}_${formattedEnd}.xlsx`
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Order Report
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={5}
        mb={2}
      >
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchReport}
          disabled={loading}
        >
          Fetch Report
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={exportExcel}
          disabled={!reportData}
        >
          Export Excel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={exportExcelProducts}
          disabled={!reportData}
        >
          Export Excel All Product Qty
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowTable((prev) => !prev)}
        >
          {showTable ? "Hide Table" : "Show Table"}
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && reportData && (
          <>
            {showTable ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell>PID</TableCell>
                      <TableCell>ExPID</TableCell>
                      <TableCell>Payment OID</TableCell>
                      <TableCell>CreatedAt</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Tip</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>GST</TableCell>
                      <TableCell>PST</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Payment Type</TableCell>
                      <TableCell>Order Type</TableCell>
                      <TableCell>Staff Name</TableCell>
                      <TableCell>Gift Code</TableCell>
                      <TableCell>Total Price</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Meta Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData?.map((order) => {
                      const orderTotal = calculateOrderTotal(
                        order.order_details
                      );
                      return (
                        <TableRow key={order.ID}>
                          <TableCell>{order.PaymentID}</TableCell>
                          <TableCell>{order.payment_id}</TableCell>
                          <TableCell>{order.external_payment_id}</TableCell>
                          <TableCell>{order.payment_order_id}</TableCell>
                          <TableCell>
                            {dayjs(order.CreatedAt).format(
                              "DD/MM/YYYY, h:mm:ss A"
                            )}
                          </TableCell>
                          <TableCell>{order.user_name}</TableCell>
                          <TableCell>{order.total_products}</TableCell>
                          <TableCell>{order.tip}</TableCell>
                          <TableCell>{order.status}</TableCell>
                          <TableCell>{order.gst}</TableCell>
                          <TableCell>{order.pst}</TableCell>
                          <TableCell>{order.percent_discount}</TableCell>
                          <TableCell>{order.payment_type}</TableCell>
                          <TableCell>{order.order_type}</TableCell>
                          <TableCell>{order.staff_name}</TableCell>
                          <TableCell>{order.gift_code}</TableCell>
                          <TableCell>{orderTotal.toFixed(2)}</TableCell>
                          <TableCell>{renderOrderDetails(order)}</TableCell>
                          <TableCell>{order.metadata}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={reportData?.all_order_details?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Revenue Comparison (Last 7 Days vs Same Period Last Week)
                    </Typography>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={barChartOptions}
                    />
                  </Grid>
                  {realtimeCategoryCharts()}
                </Grid>
                <SummaryUI
                  productSummary={reportData?.productSummary || {}}
                  modifierSummary={reportData?.modifierSummary || {}}
                  category={reportData?.group_category || []}
                />
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default OrderReport;
