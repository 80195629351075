import {
  CloudDownload as FileDownloadIcon,
  CloudUpload as FileUploadIcon,
  Inventory2 as InventoryIcon,
  Category as MaterialIcon,
  SwapHoriz as SwapIcon,
  Straighten as UnitIcon,
  Store as WarehouseIcon,
} from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LayersIcon from "@mui/icons-material/Layers";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StraightenIcon from "@mui/icons-material/Straighten";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import TuneIcon from "@mui/icons-material/Tune";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import RightDropdownMenu from "../RightDropdownMenu";
import config from "../../config";
import permissions from "../../utils/permissions"; // Import danh sách quyền
import routes from "../../utils/routes"; // Import danh sách route

const API_URL = config.apiBaseUrl;

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// const Layout = () => {
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   // phân quyền
//   const token = localStorage.getItem("token");
//   if (!token) {
//     return <Navigate to="/login" />;
//   }
//   const user = jwtDecode(token);
//   const userRole = user?.role || "cashier"; // Mặc định cashier nếu không có role
//   const allowedRoutes = permissions[userRole] || [];

//   // Hàm kiểm tra quyền truy cập menu
//   const canAccess = (path) => allowedRoutes.includes(path);
//   console.log(canAccess("/user-management"));

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <AppBarStyled position="fixed" open={open}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{ mr: 2, ...(open && { display: "none" }) }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
//             AVIK Dashboard {API_URL}
//           </Typography>
//           <RightDropdownMenu />
//         </Toolbar>
//       </AppBarStyled>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <DrawerHeader>
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === "ltr" ? (
//               <ChevronLeftIcon />
//             ) : (
//               <ChevronRightIcon />
//             )}
//           </IconButton>
//         </DrawerHeader>

//         {canAccess("/user-management") && (
//           <List>
//             <ListItem component={Link} to="/user-management">
//               <ListItemIcon>
//                 <PeopleIcon />
//               </ListItemIcon>
//               <ListItemText primary="User Management" />
//             </ListItem>
//             <ListItem component={Link} to="/customerManager">
//               <ListItemIcon>
//                 <PeopleIcon />
//               </ListItemIcon>
//               <ListItemText primary="Customer Management" />
//             </ListItem>
//           </List>
//         )}
//         <Divider />
//         {canAccess("/reports") && (
//           <ListItem component={Link} to="/reports">
//             <ListItemIcon>
//               <LayersIcon />
//             </ListItemIcon>
//             <ListItemText primary="Report Revenue" />
//           </ListItem>
//         )}
//         {canAccess("/reportType") && (
//           <ListItem component={Link} to="/reportType">
//             <ListItemIcon>
//               <LayersIcon />
//             </ListItemIcon>
//             <ListItemText primary="Report Type" />
//           </ListItem>
//         )}
//         {canAccess("/reportsOrder") && (
//           <ListItem component={Link} to="/reportsOrder">
//             <ListItemIcon>
//               <LayersIcon />
//             </ListItemIcon>
//             <ListItemText primary="Report Order" />
//           </ListItem>
//         )}
//         {canAccess("/reportsOrderTimeRange") && (
//           <ListItem component={Link} to="/reportsOrderTimeRange">
//             <ListItemIcon>
//               <LayersIcon />
//             </ListItemIcon>
//             <ListItemText primary="Report Order Time Range" />
//           </ListItem>
//         )}
//         {canAccess("/reportProducts") && (
//           <ListItem component={Link} to="/reportProducts">
//             <ListItemIcon>
//               <LayersIcon />
//             </ListItemIcon>
//             <ListItemText primary="Report Product" />
//           </ListItem>
//         )}
//         <Divider />

//         <List>
//           {canAccess("/storeStatus") && (
//             <ListItem component={Link} to="/storeStatus">
//               <ListItemIcon>
//                 <LayersIcon />
//               </ListItemIcon>
//               <ListItemText primary="Store" />
//             </ListItem>
//           )}
//           {canAccess("/app-config") && (
//             <ListItem component={Link} to="/app-config">
//               <ListItemIcon>
//                 <LayersIcon />
//               </ListItemIcon>
//               <ListItemText primary="App" />
//             </ListItem>
//           )}
//           {canAccess("/campaign") && (
//             <ListItem component={Link} to="/campaign">
//               <ListItemIcon>
//                 <LocalOfferIcon />
//               </ListItemIcon>
//               <ListItemText primary="Campaign" />
//             </ListItem>
//           )}
//           {canAccess("/products") && (
//             <ListItem component={Link} to="/products">
//               <ListItemIcon>
//                 <ShoppingCartIcon />
//               </ListItemIcon>
//               <ListItemText primary="Products" />
//             </ListItem>
//           )}
//           {canAccess("/Sizes") && (
//             <ListItem component={Link} to="/Sizes">
//               <ListItemIcon>
//                 <StraightenIcon />
//               </ListItemIcon>
//               <ListItemText primary="Sizes" />
//             </ListItem>
//           )}
//           {canAccess("/Modifiers") && (
//             <ListItem component={Link} to="/Modifiers">
//               <ListItemIcon>
//                 <TuneIcon />
//               </ListItemIcon>
//               <ListItemText primary="Modifiers" />
//             </ListItem>
//           )}
//           {canAccess("/groupModifier") && (
//             <ListItem component={Link} to="/groupModifier">
//               <ListItemIcon>
//                 <LayersIcon />
//               </ListItemIcon>
//               <ListItemText primary="Group Modifiers" />
//             </ListItem>
//           )}
//           {canAccess("/Temperatures") && (
//             <ListItem component={Link} to="/Temperatures">
//               <ListItemIcon>
//                 <ThermostatIcon />
//               </ListItemIcon>
//               <ListItemText primary="Temperatures" />
//             </ListItem>
//           )}
//           {canAccess("/Categories") && (
//             <ListItem component={Link} to="/Categories">
//               <ListItemIcon>
//                 <CategoryIcon />
//               </ListItemIcon>
//               <ListItemText primary="Categories" />
//             </ListItem>
//           )}
//           {canAccess("/uploads") && (
//             <ListItem component={Link} to="/uploads">
//               <ListItemIcon>
//                 <CloudUploadIcon />
//               </ListItemIcon>
//               <ListItemText primary="Uploads" />
//             </ListItem>
//           )}
//           {canAccess("/combo") && (
//             <ListItem component={Link} to="/combo">
//               <ListItemIcon>
//                 <LocalOfferIcon />
//               </ListItemIcon>
//               <ListItemText primary="Combos" />
//             </ListItem>
//           )}
//           {canAccess("/menu") && (
//             <ListItem component={Link} to="/menu">
//               <ListItemIcon>
//                 <RestaurantMenuIcon />
//               </ListItemIcon>
//               <ListItemText primary="Menu" />
//             </ListItem>
//           )}
//           {canAccess("/topup") && (
//             <ListItem component={Link} to="/topup">
//               <ListItemIcon>
//                 <ShoppingCartIcon />
//               </ListItemIcon>
//               <ListItemText primary="Top-up" />
//             </ListItem>
//           )}
//           {canAccess("/advertisements") && (
//             <ListItem component={Link} to="/advertisements">
//               <ListItemIcon>
//                 <CloudUploadIcon />
//               </ListItemIcon>
//               <ListItemText primary="Advertisements" />
//             </ListItem>
//           )}
//           <Divider />

//           {canAccess("/material") && (
//             <ListItem component={Link} to="/inventory">
//               <ListItemIcon>
//                 <InventoryIcon />
//               </ListItemIcon>
//               <ListItemText primary="Inventory" />
//             </ListItem>
//           )}
//           {/* // ManufacturingForm ///  */}
//           {/* <ListItem component={Link} to="/manufacturingForm">
//             <ListItemIcon>
//               <InventoryIcon />
//             </ListItemIcon>
//             <ListItemText primary="ManufacturingForm" />
//           </ListItem> */}

//           {canAccess("/material") && (
//             <ListItem component={Link} to="/material">
//               <ListItemIcon>
//                 <MaterialIcon />
//               </ListItemIcon>
//               <ListItemText primary="Material" />
//             </ListItem>
//           )}
//           {canAccess("/importRecord") && (
//             <ListItem component={Link} to="/importRecord">
//               <ListItemIcon>
//                 <FileUploadIcon />
//               </ListItemIcon>
//               <ListItemText primary="Import Record" />
//             </ListItem>
//           )}
//           {canAccess("/exportRecord") && (
//             <ListItem component={Link} to="/exportRecord">
//               <ListItemIcon>
//                 <FileDownloadIcon />
//               </ListItemIcon>
//               <ListItemText primary="Export Record" />
//             </ListItem>
//           )}
//           {canAccess("/adjustmentRecord") && (
//             <ListItem component={Link} to="/adjustmentRecord">
//               <ListItemIcon>
//                 <FileDownloadIcon />
//               </ListItemIcon>
//               <ListItemText primary="Adjustment Record" />
//             </ListItem>
//           )}
//           {canAccess("/unitConversion") && (
//             <ListItem component={Link} to="/unitConversion">
//               <ListItemIcon>
//                 <SwapIcon />
//               </ListItemIcon>
//               <ListItemText primary="Unit Conversion" />
//             </ListItem>
//           )}
//           {canAccess("/unit") && (
//             <ListItem component={Link} to="/unit">
//               <ListItemIcon>
//                 <UnitIcon />
//               </ListItemIcon>
//               <ListItemText primary="Unit" />
//             </ListItem>
//           )}
//           {canAccess("/warehouse") && (
//             <ListItem component={Link} to="/warehouse">
//               <ListItemIcon>
//                 <WarehouseIcon />
//               </ListItemIcon>
//               <ListItemText primary="Warehouse" />
//             </ListItem>
//           )}
//         </List>
//       </Drawer>
//       <Main open={open}>
//         <DrawerHeader />
//         <Outlet />
//       </Main>
//     </Box>
//   );
// };

// export default Layout;

const Layout = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const token = localStorage.getItem("token");
  if (!token) return <Navigate to="/login" />;

  const user = jwtDecode(token);
  const userRole = user?.role || "cashier";
  const allowedRoutes = permissions[userRole] || [];

  const canAccess = (path) => allowedRoutes.includes(path);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            AVIK Dashboard {API_URL}
          </Typography>
          <RightDropdownMenu />
        </Toolbar>
      </AppBarStyled>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          {routes.map((route, index) =>
            route.type === "divider" ? (
              <Divider key={index} />
            ) : (
              canAccess(route.path) && (
                <ListItem component={Link} to={route.path} key={route.path}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItem>
              )
            )
          )}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default Layout;
