// src/components/CustomerForm.js
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomerService from "../../services/CustomerService";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  mobile_number: Yup.string()
    // .matches(/^[0-9]{10,15}$/, "Phone number must be between 10-15 digits")
    .required("Mobile number is required"),
  code: Yup.string()
    .max(10, "Code must be at most 10 characters")
    .required("Code is required"),
  //   ref_type: Yup.string()
  //     .oneOf(["KOL", "STAFF"], "Invalid ref type")
  //     .required("Referral type is required"),
});

const CustomerForm = ({ customer, onClose, onCustomerUpdated }) => {
  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      code: "",
      ref_type: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await CustomerService.createAmbass(
          values.mobile_number,
          values.code,
          customer.id,
          values.ref_type
        );
        onCustomerUpdated(); // Reload customer list
        onClose();
      } catch (error) {
        setErrors({ general: "Failed to update customer." });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add Referral for Ambassador: {customer?.username} - Role:{" "}
        {customer?.role}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="dense"
            label="Phone"
            name="mobile_number"
            fullWidth
            variant="outlined"
            value={formik.values.mobile_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mobile_number &&
              Boolean(formik.errors.mobile_number)
            }
            helperText={
              formik.touched.mobile_number && formik.errors.mobile_number
            }
          />

          <TextField
            margin="dense"
            label="Code"
            name="code"
            fullWidth
            variant="outlined"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={formik.isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            "Add Referral"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerForm;
