import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  Checkbox,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import { toast } from "react-toastify";
import config from "../../config";
import productService from "../../services/ProductService"; // Sử dụng dịch vụ sản phẩm
import { COLORS } from "../../theme/themeColor";
import AddRecipe from "./Recipelist";
import ProductScheduleBoard from "./ProductScheduleBoard";
// const BaseUrl = config.apiBaseUrl;
const BaseUrl = "https://api.avik.tech/api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.BLUE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 5px",
  },
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.BLUE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 5px",
  },

  cursor: "pointer",
  fontWeight: "bold",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    transform: "scale(1.05)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // height: 20,
}));

// Component to handle rendering each product row
const ProductRow = memo(
  ({
    product,
    gstDefault,
    pstDefault,
    orderIndexChanges,
    handleOrderIndexChange,
    applyOrderIndexChange,
    handleAdd,
    handleEditProduct,
    handleDeleteProduct,
    selectedProducts, // ✅ Nhận danh sách sản phẩm đã chọn
    setSelectedProducts, // ✅ Nhận hàm cập nhật danh sách sản phẩm đã chọn
  }) => {
    // ✅ Xử lý chọn/bỏ chọn sản phẩm
    const handleSelectProduct = () => {
      setSelectedProducts(
        (prev) =>
          prev.includes(product.ID)
            ? prev.filter((id) => id !== product.ID) // Nếu đã chọn => bỏ chọn
            : [...prev, product.ID] // Nếu chưa chọn => thêm vào danh sách
      );
    };
    return (
      <TableRow key={product.ID}>
        <TableCell>
          <Checkbox
            checked={selectedProducts.includes(product.ID)}
            onChange={() => handleSelectProduct(product.ID)}
          />
        </TableCell>
        <TableCell>{product.ID}</TableCell>
        <TableCell style={{ borderRadius: "10px", overflow: "hidden" }}>
          <img
            src={BaseUrl + product.image_link_square}
            alt={product.image_link_square}
            style={{ width: 100, borderRadius: "10px" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image";
            }}
          />
        </TableCell>
        <TableCell>
          {product.alias_name}
          {product.alias_name && " - "}
          {product.name}
        </TableCell>
        <TableCell>{product.description}</TableCell>
        <TableCell>
          {product.is_sold_out && (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              Sold Out
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          <TextField
            label="Index"
            variant="outlined"
            value={orderIndexChanges[product.ID] ?? product.order_index}
            onChange={(e) => handleOrderIndexChange(product.ID, e.target.value)}
            style={{ width: "60px", textAlign: "center" }}
          />
          {orderIndexChanges[product.ID] !== undefined &&
            orderIndexChanges[product.ID] !== product.order_index && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  applyOrderIndexChange(
                    product.ID,
                    orderIndexChanges[product.ID]
                  )
                }
              >
                Apply
              </Button>
            )}
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            color={product.recipe ? "info" : "error"}
            onClick={() => handleAdd(product)}
          >
            Recipe
          </Button>
        </TableCell>
        <TableCell>{product.price.toFixed(2)}</TableCell>
        <TableCell
          sx={{
            color: product.pst != null ? "blue" : "inherit",
            fontWeight: product.pst != null ? "bold" : "normal",
          }}
        >
          {product.gst != null ? product.gst : "Default Tax"}
        </TableCell>
        <TableCell
          sx={{
            color: product.pst != null ? "blue" : "inherit",
            fontWeight: product.pst != null ? "bold" : "normal",
          }}
        >
          {product.pst != null ? product.pst : "Default Tax"}
        </TableCell>
        <TableCell>
          {(
            product.price +
            (product.price *
              ((product.gst ?? gstDefault) + (product.pst ?? pstDefault))) /
              100
          ).toFixed(2)}
        </TableCell>

        <TableCell>{product.type}</TableCell>
        <TableCell align="center" style={{ width: "200px" }}>
          <Button
            variant="outlined"
            onClick={() => handleEditProduct(product.ID)}
            sx={{ mr: 1 }} // Margin Right: 2 đơn vị
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDeleteProduct(product.ID)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }
);

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: 0,
  });
  const [error, setError] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [selectedProductRecipe, setSelectedProductRecipe] = useState(null);
  const navigate = useNavigate(); // Thay thế useHistory bằng useNavigate
  // Tạo state để lưu danh sách sản phẩm đã được chỉnh sửa
  // const [products, setProducts] = useState(filteredProducts);
  const [orderIndexChanges, setOrderIndexChanges] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [showSchedule, setShowSchedule] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openTaxDialog, setOpenTaxDialog] = useState(false);
  const [gstValue, setGstValue] = useState("");
  const [pstValue, setPstValue] = useState("");

  const [gstDefault, setGstDefault] = useState("");
  const [pstDefault, setPstDefault] = useState("");

  const fetchProducts = async () => {
    const response = await productService.getAllProducts();
    console.log("Products", response.data);
    setProducts(response.data);
    if (response.data) {
      setFilteredProducts(response.data); // Ban đầu hiển thị tất cả sản phẩm
    }
  };

  const fetchTax = async () => {
    const res = await productService.getTax();
    const data = res.data;
    console.log(res.data);
    if (data.length > 0) {
      // Tìm giá trị PST
      const pstItem = data.find((item) => item.name === "PST");
      if (pstItem) setPstDefault(pstItem.value);

      // Tìm giá trị GST
      const gstItem = data.find((item) => item.name === "GST");
      if (gstItem) setGstDefault(gstItem.value);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchTax();
  }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (products) {
      const results = products.filter((product) =>
        Object.values(product).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredProducts(results);
    }
  }, [searchTerm, products]);

  const handleAddProduct = async () => {
    navigate("/add-product"); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleEditProduct = async (id) => {
    navigate(`/add-product/${id}`); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleDeleteProduct = async (ID) => {
    try {
      await productService.deleteProduct(ID);
      setProducts(products.filter((product) => product.ID !== ID));
      setFilteredProducts(
        filteredProducts.filter((product) => product.ID !== ID)
      ); // Cập nhật danh sách đã lọc
      toast.success("Product deleted successfully.");
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Failed to delete product.");
    }
  };

  const handleCloseForm = () => {
    fetchProducts();
    setOpenForm(false);
  };

  const handleAdd = (product) => {
    console.log("testdata", product);
    setSelectedProductRecipe(product);
    setOpenForm(true);
  };

  const handleOrderIndexChange = useCallback((productId, newIndex) => {
    setOrderIndexChanges((prev) => ({
      ...prev,
      [productId]: newIndex,
    }));
  }, []);

  // Hàm xử lý thay đổi giá trị order_index
  const applyOrderIndexChange = useCallback(
    async (id, value) => {
      let tempValue = value;
      if (tempValue == "0") {
        tempValue = "1";
      }
      setProducts((prev) =>
        prev.map((product) =>
          product.ID === id ? { ...product, order_index: tempValue } : product
        )
      );
      if (tempValue !== "") {
        const response = await productService.updateProductOrderIndex(
          id,
          parseFloat(tempValue)
        );
        console.log("Order_index", response.data);
      }

      // Sau khi lưu trữ, xóa giá trị khỏi `orderIndexChanges`
      setOrderIndexChanges((prev) => {
        const { [id]: removed, ...remaining } = prev;
        return remaining;
      });
    },
    [productService]
  );

  // Hàm xử lý sắp xếp
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Sắp xếp sản phẩm dựa trên `sortConfig`
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Hiển thị ký tự mũi tên dựa trên thứ tự sắp xếp
  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "↑" : "↓";
    }
    return null;
  };

  // ✅ Gửi cập nhật lên server
  const handleUpdateSchedule = async (products) => {
    console.log(products[0]);
    try {
      const response = await productService.updateProductSchedule(products);
      toast.success("Updated schedule successfully.");
      fetchProducts();
    } catch (error) {
      console.error("Error updating schedule:", error);
      toast.error("Failed to update schedule.");
    }
  };

  const handleUpdateTax = async () => {
    try {
      const updatedProducts = selectedProducts.map((id) => ({
        ID: id,
        gst: parseFloat(gstValue),
        pst: parseFloat(pstValue),
      }));
      console.log("check tax product", updatedProducts);

      await productService.updateProductTax(updatedProducts);

      toast.success("Updated GST & PST successfully.");
      fetchProducts(); // Refresh danh sách sản phẩm
      setOpenTaxDialog(false);
      setSelectedProducts([]); // Clear selection
      setGstValue("");
      setPstValue("");
    } catch (error) {
      console.error("Failed to update tax:", error);
      toast.error("Failed to update tax.");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Product Management
      </Typography>

      {/* Tạo hàng ngang với nút "Add Product" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {!showSchedule ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddProduct()}
            >
              Add Product
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={() => setShowSchedule(true)}
              sx={{ color: "white" }} // Đặt màu chữ thành trắng
            >
              SCHEDULE
            </Button>

            <Button
              variant="contained"
              color="warning"
              onClick={() => setOpenTaxDialog(true)}
              disabled={selectedProducts.length === 0} // Chỉ bật khi có sản phẩm được chọn
              sx={{ color: "white" }} // Đặt màu chữ thành trắng
            >
              Update Tax
            </Button>
            <Stack spacing={0} alignItems="center">
              <Typography variant="body1">Default TAX</Typography>
              <Typography variant="body1">
                GST: {gstDefault}% - PST: {pstDefault}%
              </Typography>
            </Stack>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowSchedule(false)}
          >
            BACK TO PRODUCT LIST
          </Button>
        )}

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>
      {/* Hiển thị bảng Product Management hoặc Product Schedule */}
      {!showSchedule ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCellHeader></StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("ID")}>
                  ID
                </StyledTableCellHeader>
                <StyledTableCellHeader>Image</StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("name")}>
                  Name {renderSortArrow("name")}
                </StyledTableCellHeader>
                <StyledTableCellHeader
                  onClick={() => handleSort("description")}
                >
                  Description {renderSortArrow("description")}
                </StyledTableCellHeader>
                <StyledTableCellHeader
                  onClick={() => handleSort("is_sold_out")}
                >
                  Sold Out {renderSortArrow("is_sold_out")}
                </StyledTableCellHeader>
                <StyledTableCellHeader
                  onClick={() => handleSort("order_index")}
                  align="center"
                >
                  Sort Index {renderSortArrow("order_index")}
                </StyledTableCellHeader>
                <StyledTableCellHeader>Recipe</StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("price")}>
                  Price {renderSortArrow("price")}
                </StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("gst")}>
                  GST % {renderSortArrow("gst")}
                </StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("pst")}>
                  PST % {renderSortArrow("pst")}
                </StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("price")}>
                  Price & TAX {renderSortArrow("price")}
                </StyledTableCellHeader>
                <StyledTableCellHeader onClick={() => handleSort("type")}>
                  Type {renderSortArrow("type")}
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  Actions
                </StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProducts.map((product) => (
                <ProductRow
                  key={product.ID}
                  product={product}
                  gstDefault={gstDefault}
                  pstDefault={pstDefault}
                  orderIndexChanges={orderIndexChanges}
                  handleOrderIndexChange={handleOrderIndexChange}
                  selectedProducts={selectedProducts} // ✅ Truyền danh sách sản phẩm đã chọn
                  setSelectedProducts={setSelectedProducts} // ✅ Truyền hàm cập nhật danh sách handleOrderIndexChange={handleOrderIndexChange}
                  applyOrderIndexChange={applyOrderIndexChange}
                  handleAdd={handleAdd}
                  handleEditProduct={() => {
                    handleEditProduct(product.ID);
                  }}
                  handleDeleteProduct={() => {
                    handleDeleteProduct(product.ID);
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ProductScheduleBoard
          productsData={sortedProducts}
          handleUpdateSchedule={handleUpdateSchedule}
        />
      )}
      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedProductRecipe ? "Edit Ingredient" : "Add New Ingredient"}
        </DialogTitle>
        <DialogContent>
          <AddRecipe
            existing={selectedProductRecipe}
            recipeType={"product"}
            onClose={handleCloseForm}
            // onSuccess={() => {
            //     getAdjustmentRecords();
            //     setSnackbar({ open: true, message: selectedAdjustment ? 'Cập nhật phiếu điều chỉnh thành công' : 'Thêm phiếu điều chỉnh thành công', severity: 'success' });
            // }}
            // onError={(msg) => {
            //     setSnackbar({ open: true, message: msg, severity: 'error' });
            // }}
          />
        </DialogContent>
      </Dialog>
      {/* dialog tax */}
      <Dialog
        open={openTaxDialog}
        onClose={() => setOpenTaxDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update GST & PST</DialogTitle>
        <DialogContent>
          <TextField
            label="GST (%)"
            type="number"
            value={gstValue}
            onChange={(e) => setGstValue(e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="PST (%)"
            type="number"
            value={pstValue}
            onChange={(e) => setPstValue(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTaxDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateTax} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductManagement;
