import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  CircularProgress,
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import dayjs from "dayjs";
import {
  fetchReportWallet,
  fetchReportWalletUsers,
} from "../../services/ReportService";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Wallet = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const currentYear = dayjs().year();

  // Ngày bắt đầu của năm hiện tại: "YYYY-01-01"
  const startOfYear = dayjs(`${currentYear}-01-01`).format("YYYY-MM-DD");

  // Ngày kết thúc của năm hiện tại: "YYYY-12-31"
  const endOfYear = dayjs(`${currentYear}-12-31`).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(startOfYear);
  const [endDate, setEndDate] = useState(endOfYear);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [utxoData, setUtxoData] = useState([]);
  const [data, setData] = useState({});
  const [totalDeductedSum, setTotalDeductedSum] = useState(0); // ✅ State để lưu tổng `TotalDeducted`
  const [walletUsersData, setWalletUsersData] = useState([]);
  const [showWalletUsers, setShowWalletUsers] = useState(false); // Ẩn/hiện bảng report
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errorUsers, setErrorUsers] = useState(null);

  // **Tính tổng giá trị**
  const totalTopup = data.TotalMainWalletTopup || 0;
  const totalExpensePromotion = data.TotalPromotionSpend || 0;
  const totalExpense = data.TotalMainWalletSpend || 0;
  const totalPromotion = data.TotalEarningTopup + data.TotalPromotionTopup || 0;

  console.log(
    "total wallet: ",
    data.TotalPromotionSpend + data.TotalMainWalletSpend
  );

  // Cấu hình hiển thị cho **Tổng Nạp (Thu Nhập)**
  const incomeCards = [
    // { label: "Tổng Nạp", key: "TotalTopUp", color: "#1976D2", icon: <MonetizationOnIcon /> },
    {
      label: "Nạp từ Ví Chính",
      key: "TotalMainWalletTopup",
      color: "#D32F2F",
      icon: <AttachMoneyIcon />,
    },
    // { label: "Nạp từ Khuyến Mãi", key: "TotalPromotionTopup", color: "#388E3C", icon: <TrendingUpIcon /> },
    // { label: "Nạp từ Thu Nhập", key: "TotalEarningTopup", color: "#FFA000", icon: <AccountBalanceWalletIcon /> },
    // ✅ Thêm mục "Tổng Khuyến Mãi" (Tổng của TotalPromotionTopup + TotalEarningTopup)
    // { label: "Tổng Khuyến Mãi", key: "TotalPromotion", value: totalPromotion, color: "#FFA000", icon: <AccountBalanceWalletIcon /> },
  ];
  const incomePromotion = [
    // { label: "Tổng Nạp", key: "TotalTopUp", color: "#1976D2", icon: <MonetizationOnIcon /> },
    {
      label: "Nạp từ Khuyến Mãi",
      key: "TotalPromotionTopup",
      color: "#388E3C",
      icon: <TrendingUpIcon />,
    },
    {
      label: "Nạp từ Thu Nhập",
      key: "TotalEarningTopup",
      color: "#FFA000",
      icon: <AccountBalanceWalletIcon />,
    },
  ];
  // Cấu hình hiển thị cho **Tổng Tiêu (Chi Tiêu)**
  const expenseCards = [
    // { label: "Tổng Chi Tiêu", key: "TotalSpend", color: "#0288D1", icon: <ShoppingCartIcon /> },
    {
      label: "Chi Tiêu từ Ví Chính",
      key: "TotalMainWalletSpend",
      color: "#C2185B",
      icon: <PaymentIcon />,
    },
    // {
    //   label: "Chi Tiêu từ Ví phụ",
    //   key: "TotalPromotionSpend",
    //   color: "#7B1FA2",
    //   icon: <TrendingUpIcon />,
    // },
  ];
  const expenseCardsPromotion = [
    // { label: "Tổng Chi Tiêu", key: "TotalSpend", color: "#0288D1", icon: <ShoppingCartIcon /> },
    // {
    //   label: "Chi Tiêu từ Ví Chính",
    //   key: "TotalMainWalletSpend",
    //   color: "#C2185B",
    //   icon: <PaymentIcon />,
    // },
    {
      label: "Chi Tiêu từ Ví phụ",
      key: "TotalPromotionSpend",
      color: "#7B1FA2",
      icon: <TrendingUpIcon />,
    },
  ];

  const DashboardSummary = () => {
    return (
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Grid container spacing={3}>
          {/* Cột bên trái - Tổng Nạp */}
          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#1976D2",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Nạp (${data.TotalTopUp?.toFixed(2)})
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#1976D2",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Nạp Ví Chính (${totalTopup?.toFixed(2)})
            </Typography>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {incomeCards.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.key}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Card
                    sx={{
                      backgroundColor: item.color,
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                    }}
                  >
                    {item.icon}
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "0.9rem", sm: "1.2rem" },
                          // fontWeight: "bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      {/* <Typography variant="h4" sx={{ fontSize: { xs: "1rem", sm: "1.5rem" }, fontWeight: "bold" }}>
                      ${data[item.key]?.toFixed(2)}
                      </Typography> */}
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          fontWeight: "bold",
                        }}
                      >
                        $
                        {item.value !== undefined
                          ? item.value.toFixed(2).toLocaleString()
                          : data[item.key]?.toFixed(2).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Divider (Đường kẻ dọc) chỉ hiển thị trên màn hình lớn */}
          <Grid item md={0.5} sx={{ display: { xs: "none", md: "block" } }}>
            <Divider orientation="vertical" flexItem />
          </Grid>

          {/* Cột bên phải - Tổng Chi */}
          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#D32F2F",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Chi Tiêu (${data.TotalSpend?.toFixed(2)})
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#D32F2F",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Chi Tiêu Ví Chính (${totalExpense.toFixed(2)})
            </Typography>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {expenseCards.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.key}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Card
                    sx={{
                      backgroundColor: item.color,
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                    }}
                  >
                    {item.icon}
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "0.9rem", sm: "1.2rem" },
                          // fontWeight: "bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          fontWeight: "bold",
                        }}
                      >
                        ${data[item.key]?.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Cột bên trái - Tổng Nạp */}
          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#1976D2",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Nạp Ví Phụ (${totalPromotion?.toFixed(2)})
            </Typography>
            <Grid container spacing={2}>
              {incomePromotion.map((item) => (
                <Grid item xs={12} sm={6} key={item.key}>
                  <Card
                    sx={{
                      backgroundColor: item.color,
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                    }}
                  >
                    {item.icon}
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "0.9rem", sm: "1.2rem" },
                          // fontWeight: "bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          fontWeight: "bold",
                        }}
                      >
                        ${data[item.key]?.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Divider (Đường kẻ dọc) chỉ hiển thị trên màn hình lớn */}
          <Grid item md={0.5} sx={{ display: { xs: "none", md: "block" } }}>
            <Divider orientation="vertical" flexItem />
          </Grid>

          {/* Cột bên phải - Tổng Chi */}
          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#D32F2F",
                textAlign: "center",
                mb: 2,
              }}
            >
              Tổng Chi Tiêu Ví Phụ (${totalExpensePromotion.toFixed(2)})
            </Typography>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {expenseCardsPromotion.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.key}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Card
                    sx={{
                      backgroundColor: item.color,
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                    }}
                  >
                    {item.icon}
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "0.9rem", sm: "1.2rem" },
                          // fontWeight: "bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          fontWeight: "bold",
                        }}
                      >
                        ${data[item.key]?.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleFetchReportUsers = async () => {
    setLoadingUsers(true);
    setErrorUsers(null);

    try {
      const response = await fetchReportWalletUsers(endDate);
      console.log("Wallet Users Report:", response.data);
      setWalletUsersData(response.data?.data || []);
      // setShowWalletUsers(true); // Hiện bảng khi có dữ liệu
    } catch (error) {
      console.error("Error fetching wallet users report:", error);
      setErrorUsers("Failed to fetch wallet users report.");
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleShowReportUsers = () => {
    setShowWalletUsers(true); // Hiện bảng khi có dữ liệu
  };

  useEffect(() => {
    console.log("date", endDate);
    handleFetchReport();
    // report wallet users
    handleFetchReportUsers();
  }, [startDate, endDate]);

  const handleFetchReport = async () => {
    setLoading(true);
    setError(null);

    try {
      const res = await fetchReportWallet(startDate, endDate);
      console.log(res.data);
      if (res?.data) {
        setData(res.data);
        setUtxoData(res.data.utxo_values);
        // ✅ Tính tổng `TotalDeducted`
        const total =
          res.data.utxo_values?.reduce(
            (acc, row) => acc + row.TotalDeducted,
            0
          ) || 0;
        setTotalDeductedSum(total);
      }
    } catch (err) {
      console.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Hàm xuất file Excel
  const exportToExcel = () => {
    if (walletUsersData.length === 0) {
      alert("No data available to export!");
      return;
    }

    // Chuyển dữ liệu thành mảng cho Excel
    const worksheet = XLSX.utils.json_to_sheet(walletUsersData);

    // Tạo workbook và thêm worksheet vào
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Wallet Users");

    // Xuất file
    XLSX.writeFile(workbook, `Wallet_Report_${endDate}.xlsx`);
  };

  return (
    <div>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />

      {/* Hiển thị nút "Report Wallet Users" hoặc "Back" */}
      {showWalletUsers ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowWalletUsers(false)}
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}
            sx={{ marginLeft: 1, color: "white" }} // Đặt màu chữ thành trắng
          >
            Export to Excel
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowReportUsers}
          style={{ marginLeft: 10 }}
        >
          Report Wallet Users
        </Button>
      )}

      {loading && (
        <Box mt={2}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {/* Hiển thị Dashboard nếu không phải xem Report Wallet Users */}
      {!showWalletUsers && !loading && !error && (
        <>
          <DashboardSummary />
          <TableContainer component={Paper}>
            <Typography variant="h6" sx={{ m: 2 }}>
              📊 UTXO Error ({startDate} - {endDate}) | 🔥 Total UTXO: $
              {totalDeductedSum.toFixed(2)}
            </Typography>
            <Typography variant="body1" sx={{ m: 2, color: "gray" }}>
              This report provides an overview of unrecorded transactions from{" "}
              <strong>{startDate}</strong> to <strong>{endDate}</strong>.
            </Typography>
            {/* <Typography variant="body1" sx={{ m: 2, color: "gray" }}>
              Báo cáo này cung cấp tổng quan về các giao dịch chưa được ghi nhận
              trong khoảng thời gian từ <strong>{startDate}</strong> đến{" "}
              <strong>{endDate}</strong>.
            </Typography> */}

            {loading ? (
              <CircularProgress sx={{ display: "block", mx: "auto", my: 4 }} />
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Order ID</strong>
                    </TableCell>
                    <TableCell>
                      <strong>User Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Transaction ID</strong>
                    </TableCell>
                    <TableCell>
                      <strong>UTXO ID</strong>
                    </TableCell>
                    <TableCell>
                      <strong>UTXO Amount</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Ref Amount</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Deducted Amount</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Transaction Amount</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Spent</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell>
                      <strong>TopUp Ref ID</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {utxoData?.length > 0 ? (
                    utxoData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.OrderID}</TableCell>
                        <TableCell>{row.UserName}</TableCell>
                        <TableCell>{row.TransactionID}</TableCell>
                        <TableCell>{row.UTXO_ID || "N/A"}</TableCell>
                        <TableCell>{row.UTXOAmount}</TableCell>
                        <TableCell>{row.RefAmount}</TableCell>
                        <TableCell>{row.DeductedAmount}</TableCell>
                        <TableCell>{row.TotalDeducted}</TableCell>
                        <TableCell>{row.Spent}</TableCell>
                        <TableCell>{row.UTXOType || "N/A"}</TableCell>
                        <TableCell>{row.TopUpRefID || "N/A"}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </>
      )}

      {/* Report Wallet Users Table */}
      {showWalletUsers && (
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            📊 Report Wallet Users ({endDate})
          </Typography>
          {/* <Typography variant="body1" sx={{ m: 2, color: "gray" }}>
            Báo cáo này hiển thị số dư ví chính và ví phụ của người dùng tại
            thời điểm
            <strong> {endDate}</strong>. Số dư được tính dựa trên giao dịch gần
            nhất trước hoặc trong ngày báo cáo.
          </Typography> */}
          <Typography variant="body1" sx={{ m: 2, color: "gray" }}>
            This report displays the main and promotional wallet balances of
            users as of
            <strong> {endDate}</strong>. The balance is calculated based on the
            most recent transaction before or on the report date.
          </Typography>

          {loadingUsers ? (
            <CircularProgress sx={{ display: "block", mx: "auto", my: 4 }} />
          ) : errorUsers ? (
            <Alert severity="error">{errorUsers}</Alert>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>User ID</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Username</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Mobile</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Main Balance</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Promotion Balance</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {walletUsersData.length > 0 ? (
                  walletUsersData.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.user_id}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.mobile_number}</TableCell>
                      <TableCell>${user.main_balance.toFixed(2)}</TableCell>
                      <TableCell>
                        ${user.promotion_balance.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default Wallet;
