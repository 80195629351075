import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import config from "../../config";
import InventoryService from "../../services/InventoryService";
import { COLORS } from "../../theme/themeColor";
import MaterialForm from "./MaterialForm";
const BaseUrl = config.apiBaseUrl;

const MaterialManagement = () => {
  const [campaign, setCampaign] = useState([]);
  const [filteredMaterial, setFilteredMaterial] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedcampaign, setSelectedcampaign] = useState(null);
  const [openAddcampaignDialog, setOpenAddcampaignDialog] = useState(false);
  const [newcampaign, setNewcampaign] = useState({
    name: "",
    description: "",
    price: 0,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Thay thế useHistory bằng useNavigate
  const [openForm, setOpenForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  const fetchMaterial = async () => {
    const response = await InventoryService.getAllMaterial();
    console.log("Material", response.data);
    setCampaign(response.data);
    if (response.data) {
      setFilteredMaterial(response.data); // Ban đầu hiển thị tất cả sản phẩm
    }
  };
  useEffect(() => {
    fetchMaterial();
  }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (campaign) {
      const results = campaign.filter((campaign) =>
        Object.values(campaign).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredMaterial(results);
    }
  }, [searchTerm, campaign]);

  const handleAddItem = async () => {
    navigate("/materialForm"); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleEditItem = async (id) => {
    navigate(`/materialForm/${id}`); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    fetchMaterial();
  };

  const handleOpenForm = (product) => {
    console.log("testdata", product);
    setSelectedProduct(product);
    setOpenForm(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Material Management
      </Typography>

      {/* Tạo hàng ngang với nút "Add campaign" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenForm()}
        >
          Add Material
        </Button>

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Unit</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMaterial.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{item.ID}</StyledTableCell>
                <StyledTableCell>
                  {" "}
                  {moment(item.UpdatedAt).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell align="center">{item.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.description}
                </StyledTableCell>
                <StyledTableCell align="center">{item.unit}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => handleOpenForm(item)}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeletecampaign(campaign.ID)}
                >
                    Delete
                </Button> */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedProduct ? "Edit Material" : "Add New Material"}
        </DialogTitle>
        <DialogContent>
          <MaterialForm
            existing={selectedProduct}
            // recipeType={'modifier'}
            onClose={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MaterialManagement;
